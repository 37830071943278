import React from 'react';
import './SetPassword.css';
import { useState } from 'react';
import { useAuthContext } from "../../hooks/useAuthContext";

const SetPassword = () => {

  const { user } = useAuthContext();
  console.log(user);

  //used to get the data of email from the outlook
  const queryParams = new URLSearchParams(window.location.search);
  const receivedEmail = queryParams.get('emailContent');
  const receivedSecurityRole = queryParams.get('value1');


  const txt = {
    marginBottom: '20px',
    marginTop: '30px'
  };

  const [email, setEmail] = useState(receivedEmail || '');
  const [securityRole, setSecurityRole] = useState(receivedSecurityRole || '');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');


  
  console.log(email);
  console.log(securityRole);
  console.log(password);
  console.log(confirmPassword);



  const savePeopleLoginData = async (e) => {
    e.preventDefault();
  
    if (password === confirmPassword) {
      try {
        const response = await fetch('http://localhost:4001/api/people/add', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${user.token}`,
          },
          body: JSON.stringify({
            people_email: email,
            people_securityRole: securityRole,
            people_password: password,
          }),
        });
  
        if (response.ok) {
          alert('Password Updated Successfully!');
          window.location.href = "/";
        } else {
          alert('Unable to update password!');
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      alert('Both the passwords did not match! Please try again.');
    }
  };
  
  
  return (
    <>
      <div id='container'>
        <form id='setpassword'>
          <h3 style={txt}>Set Password</h3>
          <input style={txt} type='email' value={email} hidden readOnly />
          <input
            style={txt}
            type='password'
            id='pass'
            placeholder='Enter Your Password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <label>PASSWORD</label>
          <input
            style={txt}
            type='password'
            id='cpass'
            placeholder='Re-type above password'
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)} // Fixed typo here
          />
          <label>RETYPE NEW PASSWORD</label>
          <br />
          <button id='savepassword' onClick={savePeopleLoginData}>
            SAVE
          </button>
        </form>
      </div>
    </>
  );
};

export default SetPassword;
