import React, { useState, useEffect, useRef } from 'react'
import Navbar from '../../components/Navbar'
import Sidebar from '../../components/Sidebar'
import { myFunctionA, myFunctionB, accountList, exportData } from './AccountFlex'
import WriteFlex from '../../components/WriteFlex'
import {
  handleOpenClose, copyText, copyLink, handleClickFiles, handleClickNotes, handleClickExternal,
  handleClickTimestamp, handleClickInternal, handleClickLink, displayFileName
} from './AccountSidebar';
import HelpRequest from '../../components/HelpRequest';

import { faCaretUp, faCaretDown,faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuthContext } from "../../hooks/useAuthContext";


const CreateAccount = () => {



  const { user } = useAuthContext();
  console.log(user);

  const [accountname, setAccountName] = useState('');

  const [parentaccount, setParentAccount] = useState('');
  const [description, setDescription] = useState('');
  // const [region, setRegion] = useState('');
  // const [industry, setIndustry] = useState('');
  // const [vertical, setVertical] = useState('');
  // const [type, setType] = useState('');
  const [street1, setStreet1] = useState('');
  const [street2, setStreet2] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');
  const [country, setCountry] = useState('');
  const [phone, setPhone] = useState('');
  const [street12, setStreet12] = useState('');
  const [street21, setStreet21] = useState('');
  const [city2, setCity2] = useState('');
  const [state2, setState2] = useState('');
  const [zip2, setZip2] = useState('');
  const [country2, setCountry2] = useState('');
  const [phone2, setPhone2] = useState('');

  const [owner, setOwner] = useState('');
  const [ownerSearch, setOwnerSearch] = useState('');

  const [region, setRegion] = useState('');
  const [regionSearch, setRegionSearch] = useState('');
  
  const [industry, setIndustry] = useState('');
  const [industrySearch, setIndustrySearch] = useState('');


  const [vertical, setVertical] = useState('');
  const [verticalSearch, setVerticalSearch] = useState('');

  const [type, setType] = useState('');
  const [typeSearch, setTypeSearch] = useState('');

  // ---------------------------

  const [isOwnerOpen, setIsOwnerOpen] = useState(false);
  const [ownerOptions, setOwnerOptions] = useState([
    'SANJAY ',
    'SHARATH',
    'ULLAS',
    'MALATESH',
  ]);

  const [isRegionOpen, setIsRegionOpen] = useState(false);
  const [regionOptions, setRegionOptions] = useState([
    'AP',
    'EMEA ',
    'LA ',
    'NA ',
  ]);

  const [isIndustryOpen, setIsIndustryOpen] = useState(false);
  const [industryOptions, setIndustryOptions] = useState([
    'BUSINESS SERVICES ',
    'FINANCE AND INSURENCE',
    'MANUFACTURING 3',
    'MEDIA,ENTERTAINMENT,& LEISURE ',
    'PUBLIC SECTOR',
    'RETAIL & WHOLESALE TRADE',
    'UTILITIES & TELECOMMUNICATION',
    'OTHER',
  ]);


  const [isVerticalOpen, setIsVerticalOpen] = useState(false);
  const [verticalOptions, setVerticalOptions] = useState([
    'BANKING ',
    'CHEMICALS',
    'CONSTRUCTION & ENGG 3',
    'CONSULTING SERVICE ',
    'CONSUMER PRODUCTS ',
    'EDUCATION ',
    'ENTERTAINMENT & LEISURE',
    'FINANCIAL SERVICES',
  ]);

  const [isTypeOpen, setIsTypeOpen] = useState(false);
  const [typeOptions, setTypeOptions] = useState([
    'BANKING ',
    'CHEMICALS',
    'CONSTRUCTION & ENGG 3',
    'CONSULTING SERVICE ',
    'CONSUMER PRODUCTS ',
    'EDUCATION ',
    'ENTERTAINMENT & LEISURE',
    'FINANCIAL SERVICES',
  ]);

  const toggleDropdown = () => {
    setIsRegionOpen(!isRegionOpen);
    if (!isRegionOpen) {
      setRegionSearch('');
    }
  };

  const toggleDropdownContent1 = () => {
    setIsOwnerOpen(!isTypeOpen);
    if (!isOwnerOpen) {
      setOwnerSearch('');
    }

  };

  const toggleDropdownContent5 = () => {
    setIsIndustryOpen(!isIndustryOpen);
    if (!isIndustryOpen) {
      setIndustrySearch('');
    }

  };

  const toggleDropdownContent7 = () => {
    setIsVerticalOpen(!isVerticalOpen);
    if (!isVerticalOpen) {
      setVerticalSearch('');
    }

  };
  const toggleDropdownContent8 = () => {
    setIsTypeOpen(!isTypeOpen);
    if (!isTypeOpen) {
      setTypeSearch('');
    }

  };
  // ----------------------


  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setRegionSearch(inputValue);

    if (!isRegionOpen) {
      setIsRegionOpen(true);
    }
  };

  const handleInputContent1Change = (e) => {
    const inputValueContent1 = e.target.value;
    setOwnerSearch(inputValueContent1);

    if (!isOwnerOpen) {
      setIsOwnerOpen(true);
    }
  };

  const handleInputContent5Change = (e) => {
    const inputValueContent5 = e.target.value;
    setIndustrySearch(inputValueContent5);

    if (!isIndustryOpen) {
      setIsIndustryOpen(true);
    }
  };

  const handleInputContent7Change = (e) => {
    const inputValueContent7 = e.target.value;
    setVerticalSearch(inputValueContent7);

    if (!isVerticalOpen) {
      setIsVerticalOpen(true);
    }
  };

  const handleInputContent8Change = (e) => {
    const inputValueContent8 = e.target.value;
    setTypeSearch(inputValueContent8);

    if (!isTypeOpen) {
      setIsTypeOpen(true);
    }
  };




  const selectOption = (option) => {
    setRegion(option);
    setRegionSearch(option);
    setIsRegionOpen(false);
  };


  const content1Input = document.querySelector(".content1input");
  const selectOptionContent1 = (option) => {
    setOwner(option);
    setOwnerSearch(option);
    setIsOwnerOpen(false);
    content1Input.style.borderLeft = "0.1px solid #ccc";
  };

  const selectOptionContent5 = (option) => {
    setIndustry(option);
    setIndustrySearch(option);
    setIsIndustryOpen(false);
  };

  const selectOptionContent7 = (option) => {
    setVertical(option);
    setVerticalSearch(option);
    setIsVerticalOpen(false);
  };

  const selectOptionContent8 = (option) => {
    setType(option);
    setTypeSearch(option);
    setIsTypeOpen(false);
  };

  const filteredOptions = regionOptions.filter((option) =>
    option.toLowerCase().includes(regionSearch.toLowerCase())
  );
  const filteredOptionsContent1 = ownerOptions.filter((option) =>
    option.toLowerCase().includes(ownerSearch.toLowerCase())
  );

  const filteredOptionsContent5 = industryOptions.filter((option) =>
    option.toLowerCase().includes(industrySearch.toLowerCase())
  );

  const filteredOptionsContent7 = verticalOptions.filter((option) =>
    option.toLowerCase().includes(verticalSearch.toLowerCase())
  );

  const filteredOptionsContent8 = typeOptions.filter((option) =>
    option.toLowerCase().includes(typeSearch.toLowerCase())
  );

  const dropdownRef = useRef(null);
  const dropdownContent1Ref = useRef(null);
  const dropdownContent5Ref = useRef(null);
  const dropdownContent7Ref = useRef(null);
  const dropdownContent8Ref = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownContent1Ref.current && !dropdownContent1Ref.current.contains(event.target)) {
        setIsOwnerOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsRegionOpen(false);


      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);

    };
  }, []);



  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownContent5Ref.current && !dropdownContent5Ref.current.contains(event.target)) {
        setIsIndustryOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);



  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownContent7Ref.current && !dropdownContent7Ref.current.contains(event.target)) {
        setIsVerticalOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownContent8Ref.current && !dropdownContent8Ref.current.contains(event.target)) {
        setIsTypeOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  useEffect(() => {

  }, [user]);
  const saveaccounts = async (event) => {

    event.preventDefault();
    try {
      const response = await fetch('/api/accounts/add', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.token}`,
        },
        body: JSON.stringify({

          accounts: accountname,
          owner: owner,
          parent_account: parentaccount,
          description: description,
          region: region,
          industry: industry,
          vertical: vertical,
          type: type,
          billing_street1: street1,
          billing_street2: street2,
          billing_city: city,
          billing_state: state,
          billing_zip: zip,
          billing_country: country,
          billing_phone: phone,
          shipping_street1: street12,
          shipping_street2: street21,
          shipping_city: city2,
          shipping_state: state2,
          shipping_zip: zip2,
          shipping_country: country2,
          shipping_phone: phone2,

        }),
      });
      if (response.ok) {
        console.log('success');
        alert('Account Created Successfully.');
        window.location.href = '/account';
      } else {
        console.log('Request failed:', response.status);
        alert('Account already exits');
      }
    } catch (error) {
      console.log(error);
    }
  }
  
  const showTooltip = () => {
    const tooltip = document.getElementById('tooltip');
    tooltip.style.display = 'block';
  };

  const hideTooltip = () => {
    const tooltip = document.getElementById('tooltip');
    tooltip.style.display = 'none';
  };


  return (
    <div>
      <Navbar />
      <Sidebar />
      <div className="bread">
        <ul className="breadcrumbs">
          <li className="breadcrumbs--item">
            <a href="./home" className="breadcrumbs--link breadcrumbs" style={{ display: 'inline', textDecoration: 'none' }}>HOME</a>
          </li>
          <li className="breadcrumbs--item">
            <a href="./account" className="breadcrumbs--link breadcrumbs--link--active">ACCOUNTS</a>
          </li>
          <ol style={{ float: 'right', listStyle: 'none' }}>
            <a href="/account" className="breadcrumbs--link breadcrumbs--link--active" ><i className="fa fa-pen" style={{ fontSize: '15px', marginRight: '10px' }} /></a>
            <a href="#" className="breadcrumbs--link breadcrumbs--link--active" onClick={accountList}><i className="fa fa-table" style={{ fontSize: '15px', marginRight: '10px' }} /></a>
          </ol>
        </ul>
        <hr className="hr" />
      </div>
      <HelpRequest />
      <div className="row" id="rows">
        <WriteFlex />
        <div className="right" id="createAcc">
          <div id="accountsheader"><a>ACCOUNTS</a></div>
          <div>
            <form onSubmit={saveaccounts}>
              <div className="containerA1">
                <div id="content1">
                  <input className="inputTextA1" type="text" placeholder onChange={(e) => setAccountName(e.target.value)} value={accountname} required />
                  <label>ACCOUNTS</label>
                </div>
                <div id="content2">
                  <div id="content8Container" ref={dropdownContent1Ref}>
                    <div className='content'>
                      <input className='content1input'
                        onClick={toggleDropdownContent1}
                        value={isOwnerOpen ? ownerSearch : owner}
                        onChange={handleInputContent1Change}
                        readOnly={!isOwnerOpen} />
                    <FontAwesomeIcon icon={faUser} id="ownerimage" />
                      <span className="dropdown-icon" onClick={toggleDropdown}>
                        {isOwnerOpen ? (
                          <FontAwesomeIcon icon={faCaretUp} />
                        ) : (
                          <FontAwesomeIcon icon={faCaretDown} />
                        )}
                      </span>
                      <label className='content1owner'>OWNER</label>
                    </div>
                    {isOwnerOpen && (
                      <div className="content1dropdown">
                        {filteredOptionsContent1.length === 0 ? (
                          <div className="no-results-contnt1">
                            No results found
                          </div>
                        ) : (
                          filteredOptionsContent1.map((option, index) => (
                            <div
                              key={index}
                              className="dropdown-list-item-content1"
                              onClick={() => selectOptionContent1(option)}
                            >
                              {option}
                            </div>
                          ))
                        )}
                      </div>
                    )}

                  </div>
                </div>
              </div>
              <div className="containerA2">
                <div id="content3" className="input-container-creataccount">
                  <input type="text" className="inputTextA1" onChange={(e) => {
                    setParentAccount(e.target.value);
                    hideTooltip(); // Hide the tooltip when user starts typing
                  }}
                  
                    onFocus={showTooltip}
                    onBlur={hideTooltip} />

                  <label>PARENT ACCOUNT</label>
                  <div id="tooltip" className="tooltip">
                  PLEASE ENTER 3 OR MORE CHARACTERS
                  </div>
                </div>
              </div>
              <div className="containerA3">
                <div id="content4">
                  <input type="text" className="inputTextA1" placeholder onChange={(e) => setDescription(e.target.value)} value={description} />
                  <label>DESCRIPTION</label>
                </div>
              </div>
              <div className="containerA4">
                <div className="dropdown_list" ref={dropdownRef}>
                  <div className="dropdown-input-container">
                    <input
                      type="text"
                      className="dropdown-input"
                      value={isRegionOpen ? regionSearch : region}
                      onChange={handleInputChange}
                      onClick={toggleDropdown}
                      readOnly={!isRegionOpen} // Set readOnly attribute based on isRegionOpen
                    />
                    <span className="dropdown-icon" onClick={toggleDropdown}>
                      {isRegionOpen ? (
                        <FontAwesomeIcon icon={faCaretUp} />
                      ) : (
                        <FontAwesomeIcon icon={faCaretDown} />
                      )}
                    </span>
                    <label className='content5label'>REGION</label>

                  </div>
                  {isRegionOpen && (
                    <div
                      className="dropdown-list" id="dropdownlist"
                    >
                      {filteredOptions.length === 0 ? (
                        <div className="no-results">
                          No results found
                        </div>
                      ) : (
                        filteredOptions.map((option, index) => (
                          <div
                            key={index}
                            className="dropdown-list-item"
                            onClick={() => selectOption(option)}
                          >
                            {option}
                          </div>
                        ))
                      )}
                    </div>
                  )}
                </div>
                <div className='content5Container' ref={dropdownContent5Ref}>
                  <div id="content6">
                    <input type="text" className="contentinput6"
                      onClick={toggleDropdownContent5}
                      onChange={handleInputContent5Change}
                      value={isIndustryOpen ? industrySearch : industry}
                      readOnly={!isIndustryOpen} // Set readOnly attribute based on isOpe
                    />
                    <span className="dropdown-icon" onClick={toggleDropdown}>
                      {isIndustryOpen ? (
                        <FontAwesomeIcon icon={faCaretUp} />
                      ) : (
                        <FontAwesomeIcon icon={faCaretDown} />
                      )}
                    </span>
                    <label>INDUSTRY</label>
                  </div>
                  {isIndustryOpen && (
                    <div className="content5dropdown">
                      {filteredOptionsContent5.length === 0 ? (
                        <div className="no-results-contnt5">
                          No results found
                        </div>
                      ) : (
                        filteredOptionsContent5.map((option, index) => (
                          <div
                            key={index}
                            className="dropdown-list-item-content5"
                            onClick={() => selectOptionContent5(option)}
                          >
                            {option}
                          </div>
                        ))
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="containerA5">
                <div className='content7Container' ref={dropdownContent7Ref}>
                  <div id="content7" >
                    <input className='content7input'
                      onClick={toggleDropdownContent7}
                      value={isVerticalOpen ? verticalSearch : vertical}
                      onChange={handleInputContent7Change}
                      readOnly={!isVerticalOpen} />

                    <span className="dropdown-icon" onClick={toggleDropdown}>
                      {isVerticalOpen ? (
                        <FontAwesomeIcon icon={faCaretUp} />
                      ) : (
                        <FontAwesomeIcon icon={faCaretDown} />
                      )}
                    </span>
                    <label>VERTICAL</label>
                  </div>
                  {isVerticalOpen && (
                    <div className="content7dropdown">
                      {filteredOptionsContent7.length === 0 ? (
                        <div className="no-results-contnt7">
                          No results found
                        </div>
                      ) : (
                        filteredOptionsContent7.map((option, index) => (
                          <div
                            key={index}
                            className="dropdown-list-item-content7"
                            onClick={() => selectOptionContent7(option)}
                          >
                            {option}
                          </div>
                        ))
                      )}
                    </div>
                  )}
                </div>
                <div id="content8Container" ref={dropdownContent8Ref}>
                  <div className='content8'>
                    <input className='content8input'
                      onClick={toggleDropdownContent8}
                      value={isTypeOpen ? typeSearch : type}
                      onChange={handleInputContent8Change}
                      readOnly={!isTypeOpen} />

                    <span className="dropdown-icon" onClick={toggleDropdown}>
                      {isTypeOpen ? (
                        <FontAwesomeIcon icon={faCaretUp} />
                      ) : (
                        <FontAwesomeIcon icon={faCaretDown} />
                      )}
                    </span>
                    <label className='content8type'>TYPE</label>
                  </div>
                  {isTypeOpen && (
                    <div className="content8dropdown">
                      {filteredOptionsContent8.length === 0 ? (
                        <div className="no-results-contnt8">
                          No results found
                        </div>
                      ) : (
                        filteredOptionsContent8.map((option, index) => (
                          <div
                            key={index}
                            className="dropdown-list-item-content8"
                            onClick={() => selectOptionContent8(option)}
                          >
                            {option}
                          </div>
                        ))
                      )}
                    </div>
                  )}

                </div>
              </div>
              {/* BILLING ADDRESS */}
              <div className="containerA6">
                <div className="left1a" > <button type="button" className="bbtn" onClick={myFunctionA} id="clickme">
                  <b>BILLING ADDRESS &nbsp; <span className="fa fa-angle-down" /></b></button>
                  <div id="billOne" className="hidden">
                    <div className="content9">
                      <div className="street1">
                        <input className="billing" type="text" placeholder onChange={(e) => setStreet1(e.target.value)} value={street1} />
                        <label>STREET-1</label>
                      </div>
                      {/* </div>  <div class="content9"> */}
                      <div className="street2">
                        <input className="billing" type="text" placeholder onChange={(e) => setStreet2(e.target.value)} value={street2} />
                        <label>STREET-2</label>
                      </div>
                    </div>
                    <div className="content9">
                      <div className="street1">
                        <input className="billing" type="text" placeholder onChange={(e) => setCity(e.target.value)} value={city} />
                        <label>CITY</label>
                      </div>
                      {/* </div>  <div class="content9"> */}
                      <div className="street2">
                        <input className="billing" type="text" placeholder onChange={(e) => setState(e.target.value)} value={state} />
                        <label>STATE</label>
                      </div>
                    </div>
                    <div className="content9">
                      <div className="street1">
                        <input className="billing" type="number" placeholder onChange={(e) => setZip(e.target.value)} value={zip} /> <label>ZIP</label>
                      </div>
                      {/* </div>  <div class="content9"> */}
                      <div className="street2">
                        <input className="billing" type="text" placeholder onChange={(e) => setCountry(e.target.value)} value={country} />
                        <label>COUNTRY</label>
                      </div>
                    </div>
                    <div className="content9"> <input id="pno" className="billing" type="number" placeholder style={{ marginLeft: '0.8%' }} onChange={(e) => setPhone(e.target.value)} value={phone} />
                      <label>PHONE</label>
                    </div>
                  </div>
                </div>
                <div className="right1a"> <button type="button" onClick={myFunctionB} className="sbtn" id="clikhere">
                  <b>SHIPPING ADDRESS &nbsp;<span className="fa fa-angle-down" /></b></button>
                  <div id="ship" className="hidden">
                    <div className="content10">
                      <div className="street1">
                        <input className="billing" type="text" placeholder onChange={(e) => setStreet12(e.target.value)} value={street12} />
                        <label id="l1">STREET-1</label>
                      </div>
                      {/* <div class="content10"> */}
                      <div className="street2"> <input className="billing" type="text" placeholder onChange={(e) => setStreet21(e.target.value)} value={street21} />
                        <label>STREET-2</label>
                      </div>
                    </div>
                    <div className="content10">
                      <div className="street1">
                        <input className="billing" type="text" placeholder onChange={(e) => setCity2(e.target.value)} value={city2} /> <label>CITY</label>
                      </div>
                      {/* </div>  <div class="content10"> */}
                      <div className="street2">
                        <input className="billing" type="text" placeholder onChange={(e) => setState2(e.target.value)} value={state2} />
                        <label>STATE</label>
                      </div>
                    </div>
                    <div className="content10">
                      <div className="street1">
                        <input className="billing" id="zip" type="number" placeholder onChange={(e) => setZip2(e.target.value)} value={zip2} />
                        <label>ZIP</label>
                      </div>
                      {/* </div>  <div class="content10"> */}
                      <div className="street2">
                        <input className="billing" type="text" placeholder onChange={(e) => setCountry2(e.target.value)} value={country2} />
                        <label>COUNTRY</label>
                      </div>
                    </div>
                    <div className="content10"> <input id="pno" className="billing" type="number" placeholder style={{ marginLeft: '0.7%' }}
                      onChange={(e) => setPhone2(e.target.value)} value={phone2} />
                      <label>PHONE</label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="savcanc">
                <button id="reset" type="reset" style={{ color: 'red' }}>CANCEL</button>
                <button id="save" type="submit" style={{ color: 'green' }}>SAVE</button>
              </div>
            </form></div>

        </div>
      </div>
    </div>
  )
}

export default CreateAccount