import React, { useEffect, useRef } from "react";
import "../Accounts/Accounts.css";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import { myFunctionA, myFunctionB,myFunctionC,accountList,exportData,} from "../Accounts/AccountFlex";
import "./AccountSidebar.css";
import WriteFlex from "../../components/WriteFlex";
import HelpRequest from "../../components/HelpRequest";
import "../Accounts/AccountSidebar.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Addinfo from "../../components/Addinfo";
import { useAuthContext } from "../../hooks/useAuthContext";
import { faCaretUp, faCaretDown, faUser} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Account = () => {
  const [accountId, setAccountId] = useState("");
  const [account_key, setAccountkey] = useState("");
  const [accounts, setAccounts] = useState("");
  const [owner, setOwner] = useState("");
  const [parentAccount, setParentAccount] = useState("");
  const [description, setDescription] = useState("");
  const [region, setRegion] = useState("");
  const [industry, setIndustry] = useState("");
  const [vertical, setVertical] = useState("");
  const [type, setType] = useState("");
  const [billingStreet1, setBillingStreet1] = useState("");
  const [billingStreet2, setBillingStreet2] = useState("");
  const [billingCity, setBillingCity] = useState("");
  const [billingState, setBillingState] = useState("");
  const [billingZip, setBillingZip] = useState("");
  const [billingCountry, setBillingCountry] = useState("");
  const [billingPhone, setBillingPhone] = useState("");
  const [shippingStreet1, setShippingStreet1] = useState("");
  const [shippingStreet2, setShippingStreet2] = useState("");
  const [shippingCity, setShippingCity] = useState("");
  const [shippingState, setShippingState] = useState("");
  const [shippingZip, setShippingZip] = useState("");
  const [shippingCountry, setShippingCountry] = useState("");
  const [shippingPhone, setShippingPhone] = useState("");
// ------------------------------------

  const [ownerSearch, setOwnerSearch] = useState("");
  const [regionSearch, setRegionSearch] = useState("");
  const [industrySearch, setIndustrySearch] = useState("");
  const [verticalSearch, setVerticalSearch] = useState("");
  const [typeSearch, setTypeSearch] = useState("");

  // ---------------------------

  const [isOwnerOpen, setIsOwnerOpen] = useState(false);
  const [ownerOptions, setOwnerOptions] = useState([
    "SANJAY ",
    "SHARATH",
    "ULLAS",
    "MALATESH",
  ]);

  const [isRegionOpen, setIsRegionOpen] = useState(false);
  const [regionOptions, setRegionOptions] = useState([
    "AP",
    "EMEA ",
    "LA ",
    "NA ",
  ]);

  const [isIndustryOpen, setIsIndustryOpen] = useState(false);
  const [industryOptions, setIndustryOptions] = useState([
    "BUSINESS SERVICES ",
    "FINANCE AND INSURENCE",
    "MANUFACTURING ",
    "MEDIA,ENTERTAINMENT,& LEISURE ",
    "PUBLIC SECTOR",
    "RETAIL & WHOLESALE TRADE",
    "UTILITIES & TELECOMMUNICATION",
    "OTHER",
  ]);

  const [isVerticalOpen, setIsVerticalOpen] = useState(false);
  const [verticalOptions, setVerticalOptions] = useState([
    "BANKING ",
    "CHEMICALS",
    "CONSTRUCTION & ENGG ",
    "CONSULTING SERVICE ",
    "CONSUMER PRODUCTS ",
    "EDUCATION ",
    "ENTERTAINMENT & LEISURE",
    "FINANCIAL SERVICES",
  ]);

  const [isTypeOpen, setIsTypeOpen] = useState(false);
  const [typeOptions, setTypeOptions] = useState([
    "BANKING ",
    "CHEMICALS",
    "CONSTRUCTION & ENGG 3",
    "CONSULTING SERVICE ",
    "CONSUMER PRODUCTS ",
    "EDUCATION ",
    "ENTERTAINMENT & LEISURE",
    "FINANCIAL SERVICES",
  ]);

  const toggleDropdown = () => {
    setIsRegionOpen(!isRegionOpen);
    if (!isRegionOpen) {
      setRegionSearch("");
    }
  };

  const toggleDropdownContent1 = () => {
    setIsOwnerOpen(!isTypeOpen);
    if (!isOwnerOpen) {
      setOwnerSearch("");
    }
  };

  const toggleDropdownContent5 = () => {
    setIsIndustryOpen(!isIndustryOpen);
    if (!isIndustryOpen) {
      setIndustrySearch("");
    }
  };

  const toggleDropdownContent7 = () => {
    setIsVerticalOpen(!isVerticalOpen);
    if (!isVerticalOpen) {
      setVerticalSearch("");
    }
  };
  const toggleDropdownContent8 = () => {
    setIsTypeOpen(!isTypeOpen);
    if (!isTypeOpen) {
      setTypeSearch("");
    }
  };
  // ----------------------

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setRegionSearch(inputValue);

    if (!isRegionOpen) {
      setIsRegionOpen(true);
    }
  };

  const handleInputContent1Change = (e) => {
    const inputValueContent1 = e.target.value;
    setOwnerSearch(inputValueContent1);

    if (!isOwnerOpen) {
      setIsOwnerOpen(true);
    }
  };

  const handleInputContent5Change = (e) => {
    const inputValueContent5 = e.target.value;
    setIndustrySearch(inputValueContent5);

    if (!isIndustryOpen) {
      setIsIndustryOpen(true);
    }
  };

  const handleInputContent7Change = (e) => {
    const inputValueContent7 = e.target.value;
    setVerticalSearch(inputValueContent7);

    if (!isVerticalOpen) {
      setIsVerticalOpen(true);
    }
  };

  const handleInputContent8Change = (e) => {
    const inputValueContent8 = e.target.value;
    setTypeSearch(inputValueContent8);

    if (!isTypeOpen) {
      setIsTypeOpen(true);
    }
  };

  const selectOption = (option) => {
    setRegion(option);
    setRegionSearch(option);
    setIsRegionOpen(false);
  };

  const selectOptionContent1 = (option) => {
    setOwner(option);
    setOwnerSearch(option);
    setIsOwnerOpen(false);
  };

  const selectOptionContent5 = (option) => {
    setIndustry(option);
    setIndustrySearch(option);
    setIsIndustryOpen(false);
  };

  const selectOptionContent7 = (option) => {
    setVertical(option);
    setVerticalSearch(option);
    setIsVerticalOpen(false);
  };

  const selectOptionContent8 = (option) => {
    setType(option);
    setTypeSearch(option);
    setIsTypeOpen(false);
  };

  const filteredOptions = regionOptions.filter((option) =>
    option.toLowerCase().includes(regionSearch.toLowerCase())
  );
  const filteredOptionsContent1 = ownerOptions.filter((option) =>
    option.toLowerCase().includes(ownerSearch.toLowerCase())
  );

  const filteredOptionsContent5 = industryOptions.filter((option) =>
    option.toLowerCase().includes(industrySearch.toLowerCase())
  );

  const filteredOptionsContent7 = verticalOptions.filter((option) =>
    option.toLowerCase().includes(verticalSearch.toLowerCase())
  );

  const filteredOptionsContent8 = typeOptions.filter((option) =>
    option.toLowerCase().includes(typeSearch.toLowerCase())
  );

  const dropdownRef = useRef(null);
  const dropdownContent1Ref = useRef(null);
  const dropdownContent5Ref = useRef(null);
  const dropdownContent7Ref = useRef(null);
  const dropdownContent8Ref = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        dropdownContent1Ref.current &&
        !dropdownContent1Ref.current.contains(event.target)
      ) {
        setIsOwnerOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsRegionOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        dropdownContent5Ref.current &&
        !dropdownContent5Ref.current.contains(event.target)
      ) {
        setIsIndustryOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        dropdownContent7Ref.current &&
        !dropdownContent7Ref.current.contains(event.target)
      ) {
        setIsVerticalOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        dropdownContent8Ref.current &&
        !dropdownContent8Ref.current.contains(event.target)
      ) {
        setIsTypeOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const { user } = useAuthContext();
  console.log(user);

  //API to get data from DB
  const [dbAccountData, setDbAccountData] = useState([]);
  console.log("!@#$%^&*");
  console.log(dbAccountData);
  // console.log("ACCOUNTDATA", JSON.stringify(dbAccountData, null, 2));

  useEffect(() => {
    const getaccountdata = async () => {
      try {
        const response = await fetch("/api/accounts/get", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        });
        if (response.ok) {
          const account = await response.json();
          console.log(account);
          console.log(account.data);
          setDbAccountData(account.data);

          if (dbAccountData == null) {
            try {
              const accDaata = account.data;
            } catch (error) {
              console.log(error);
            }
          }
        } else {
          console.log("Error:", response.statusText);
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (user != "" && user != null) {
      getaccountdata();
    }
  }, [user]);

  //End of API ***********************************************************************************

  const [opportunityData, setOpportunityData] = useState([]);

  //API to send data to oppurtunityData page end

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 3;

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderTableRows = () => {
    // Calculate indexes for the current page
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    // Slice the opportunity data for the current page
    const currentOpportunityData = opportunityData.slice(startIndex, endIndex);

    // Render table rows
    return currentOpportunityData.map((row, index) => (
      <tr key={index} onClick={() => sendOpurtunity(row)}>
        <td>{row.opportunity_name}</td>
        <td>{row.quote}</td>
        <td>{row.status}</td>
        <td>{row.closeDate}</td>
        <td>{row.owner}</td>
        <td>{row.revenue}</td>
        <td>{row.margin}</td>
      </tr>
    ));
  };

  const renderPagination = () => {
    // Calculate the total number of pages
    const totalPages = Math.ceil(opportunityData.length / itemsPerPage);

    // Render pagination controls
    return (
      <div className="pagination">
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            className={currentPage === index + 1 ? "active" : ""}
            onClick={() => setCurrentPage(index + 1)}
          >
            {index + 1}
          </button>
        ))}
      </div>
    );
  };
  useEffect(() => {
    if (dbAccountData.length > 0) {
      setAccountId(dbAccountData[0].account_id || "");
      setAccountkey(dbAccountData[0].account_key || "");
      setAccounts(dbAccountData[0].accounts || "");
      setOwner(dbAccountData[0].owner || "");
      setParentAccount(dbAccountData[0].parent_account || "");
      setDescription(dbAccountData[0].description || "");
      setRegion(dbAccountData[0].region || "");
      setIndustry(dbAccountData[0].industry || "");
      setVertical(dbAccountData[0].vertical || "");
      setType(dbAccountData[0].type || "");
      setBillingStreet1(dbAccountData[0].billing_street1 || "");
      setBillingStreet2(dbAccountData[0].billing_street2 || "");
      setBillingCity(dbAccountData[0].billing_city || "");
      setBillingState(dbAccountData[0].billing_state || "");
      setBillingZip(dbAccountData[0].billing_zip || "");
      setBillingCountry(dbAccountData[0].billing_country || "");
      setBillingPhone(dbAccountData[0].billing_phone || "");
      setShippingStreet1(dbAccountData[0].shipping_street1 || "");
      setShippingStreet2(dbAccountData[0].shipping_street2 || "");
      setShippingCity(dbAccountData[0].shipping_city || "");
      setShippingState(dbAccountData[0].shipping_state || "");
      setShippingZip(dbAccountData[0].shipping_zip || "");
      setShippingCountry(dbAccountData[0].shipping_country || "");
      setShippingPhone(dbAccountData[0].shipping_phone || "");
    }
  }, [dbAccountData]);

  //Setting useState when clicked on the rightsidebar
  const [selectedUser, setSelectedUser] = useState(null);
  const oppopagination1 = document.getElementById("oppopagination");
  const save_cancel = document.getElementById("save_cancel");
  const onSelectUser = (account) => {
    setSelectedUser(account); // Update the selectedUser state when a user is clicked in the sidebar
    setAccountId(account.account_id || "");
    setAccountkey(account.account_key || "");
    setAccounts(account.accounts || "");
    setOwner(account.owner || "");
    setParentAccount(account.parent_account || "");
    setDescription(account.description || "");
    setRegion(account.region || "");
    setIndustry(account.industry || "");
    setVertical(account.vertical || "");
    setType(account.type || "");
    setBillingStreet1(account.billing_street1 || "");
    setBillingStreet2(account.billing_street2 || "");
    setBillingCity(account.billing_city || "");
    setBillingState(account.billing_state || "");
    setBillingZip(account.billing_zip || "");
    setBillingCountry(account.billing_country || "");
    setBillingPhone(account.billing_phone || "");
    setShippingStreet1(account.shipping_street1 || "");
    setShippingStreet2(account.shipping_street2 || "");
    setShippingCity(account.shipping_city || "");
    setShippingState(account.shipping_state || "");
    setShippingZip(account.shipping_zip || "");
    setShippingCountry(account.shipping_country || "");
    setShippingPhone(account.shipping_phone || "");
    oppopagination1.style.display = "block";
    save_cancel.style.display = "none";
  };

  //****************************************************************************************************/

  const handleUpdateAccount = () => {
    fetch(`/api/accounts/update/${accountId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify({
        accounts: accounts,
        owner: owner,
        parent_account: parentAccount,
        description: description,
        region: region,
        industry: industry,
        vertical: vertical,
        type: type,
        billing_street1: billingStreet1,
        billing_street2: billingStreet2,
        billing_city: billingCity,
        billing_state: billingState,
        billing_zip: billingZip,
        billing_country: billingCountry,
        billing_phone: billingPhone,
        shipping_street1: shippingStreet1,
        shipping_street2: shippingStreet2,
        shipping_city: shippingCity,
        shipping_state: shippingState,
        shipping_zip: shippingZip,
        shipping_country: shippingCountry,
        shipping_phone: shippingPhone,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Error updating Account");
        }
      })
      .then((data) => {
        console.log("Account updated successfully!", data);
        alert("Account updated successfully!");
        window.location.href = "/account";
        // You can choose to update the UI or perform any other actions here
      })
      .catch((error) => {
        console.error("Error updating Account:", error);
      });
  };

  const handleDeleteAccount = () => {
    fetch(`http://localhost:4001/api/accounts/delete/${accountId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Account Deleted successfully!", data);
        alert("Account Deleted successfully!");
        window.location.href = "/account";
      })
      .catch((error) => {
        console.error("Error Deleting Account:", error);
      });
  };

  //****************************************Opportunity section start ***************************************
  useEffect(() => {
    const getOpportunityData = async () => {
      try {
        const response = await fetch("/api/opportunity/get", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
          body: JSON.stringify({
            account_key: account_key,
          }),
        });
        if (response.ok) {
          const oppData = await response.json();
          console.log(oppData);
          console.log(
            oppData.data + "-----+--------+-------+--------+-------+"
          );
          setOpportunityData(oppData.data);

          if (opportunityData == null) {
            try {
              const opporData = oppData.data[0];
            } catch (error) {
              console.log(error);
            }
          }
        } else {
          console.log("Error:", response.statusText);
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (user != "" && user != null) {
      getOpportunityData();
    }
  }, [user, account_key]);

  //API to send data to oppurtunityData page
  const navigate = useNavigate();

  const sendOpurtunity = async (row) => {
    try {
      navigate("/opportunitiesdata", { state: { row } });
    } catch (error) {
      console.log(error);
    }
  };
  const showTooltip = () => {
    const tooltip = document.getElementById("tooltipaccount");
    tooltip.style.display = "block";
  };

  const hideTooltip = () => {
    const tooltip = document.getElementById("tooltipaccount");
    tooltip.style.display = "none";
  };

  //Opportunity section end *******************************************************************************
  const handleAddAccount = () => {
    if (!accounts || !owner) {
      toast.info("Please fill in the 'ACCOUNT' and 'OWNER' fields.");
      return;
    }
    const newAccountData = {
      accounts: accounts,
      owner: owner,
      parent_account: parentAccount,
      description: description,
      region: region,
      industry: industry,
      vertical: vertical,
      type: type,
      billing_street1: billingStreet1,
      billing_street2: billingStreet2,
      billing_city: billingCity,
      billing_state: billingState,
      billing_zip: billingZip,
      billing_country: billingCountry,
      billing_phone: billingPhone,
      shipping_street1: shippingStreet1,
      shipping_street2: shippingStreet2,
      shipping_city: shippingCity,
      shipping_state: shippingState,
      shipping_zip: shippingZip,
      shipping_country: shippingCountry,
      shipping_phone: shippingPhone,
    };

    fetch("/api/accounts/add", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify(newAccountData),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Error adding Account");
        }
      })
      .then((data) => {
        alert("account added successfully");
        console.log("Account added successfully!", data);
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error adding Account:", error);
      });
  };
  const oppopagination = document.getElementById("oppopagination");
  const save_cancelAccount = document.getElementById("save_cancel");
  const resetInputFields = () => {
    setAccounts("");
    setOwner("");
    setParentAccount("");
    setDescription("");
    setRegion("");
    setIndustry("");
    setVertical("");
    setType("");
    setBillingStreet1("");
    setBillingStreet2("");
    setBillingCity("");
    setBillingState("");
    setBillingZip("");
    setBillingCountry("");
    setBillingPhone("");
    setShippingStreet1("");
    setShippingStreet2("");
    setShippingCity("");
    setShippingState("");
    setShippingZip("");
    setShippingCountry("");
    setShippingPhone("");
    oppopagination.style.display = "none";
    save_cancelAccount.style.display = "block";
  };
  const [accountName, setAccountName] = useState("");

  const [displayErrorMessageAccount, setDisplayErrorMessageAccount] =
    useState(false);
  const [displayAccountName, setDisplayAccountName] = useState(true);
  const inputTextA1 = document.querySelector(".inputTextA1");
 
  const handleAccountBlur = () => {
    const trimmedValue = accounts.trim();
    if (trimmedValue === "") {
      setDisplayErrorMessageAccount(true);
      setDisplayAccountName(false);
      inputTextA1.style.outlineColor = "red";
      inputTextA1.style.border = " 2px solid red";
      inputTextA1.style.borderLeft = "0px";
    } else {
      setDisplayErrorMessageAccount(false);
      setDisplayAccountName(true);
      inputTextA1.style.outlineColor = "#045679";
      inputTextA1.style.border = " 1px solid #ccc";
    }
  };

  return (
    <div>
      <Navbar />
      <Sidebar />
      <div className="bread">
        <ul className="breadcrumbs">
          <li className="breadcrumbs--item">
            <a
              href="./home"
              className="breadcrumbs--link breadcrumbs"
              style={{ display: "inline", textDecoration: "none" }}
            >
              HOME
            </a>
          </li>
          <li className="breadcrumbs--item">
            <a
              href="./account"
              className="breadcrumbs--link breadcrumbs--link--active"
            >
              ACCOUNTS
            </a>
          </li>
          <li className="breadcrumbs--item">
            <a
              href="./account"
              className="breadcrumbs--link breadcrumbs--link--active"
            >
              {accounts}
            </a>
          </li>
          <ol style={{ float: "right", listStyle: "none" }}>
            <a
              href="/account"
              className="breadcrumbs--link breadcrumbs--link--active"
            >
              <i
                className="fa fa-pen"
                style={{ fontSize: "15px", marginRight: "10px" }}
              />
            </a>
            <a
              href="#"
              className="breadcrumbs--link breadcrumbs--link--active"
              onClick={accountList}
            >
              <i
                className="fa fa-table"
                style={{ fontSize: "15px", marginRight: "10px" }}
              />
            </a>
          </ol>
        </ul>
        <hr className="hr" />
      </div>
      <HelpRequest />
      <div className="row" id="rows">
        <WriteFlex
          dbAccountData={dbAccountData}
          onSelectUser={onSelectUser}
          resetInputFields={resetInputFields}
        />

        {/* <div className="plusicon">
          <a href="/account/create"><i className="fa fa-plus" /></a>
        </div> */}
        <div className="right">
          <div id="accountsheader">
            <a>ACCOUNTS</a>
          </div>
          <div>
            <div className="containerA1">
              <div className="containeraccounts"
                style={{
                  borderLeft:
                    accounts.length === 0 ? "3px solid #216c98" : "none",
                }}>
              
                <input
                  className="inputTextA1"
                  type="text"
                  placeholder
                  value={accounts}
                  onChange={(e) => setAccounts(e.target.value)}
                  id="accountsInput"
                  onBlur={handleAccountBlur}
                />
                {displayAccountName && (
                  <label className="accountname">ACCOUNTS</label>
                )}

                {displayErrorMessageAccount && (
                  <label className="errormsg">Required</label>
                )}
              </div>
              <div id="content2">
                <div id="content8Container" ref={dropdownContent1Ref}>
                  <div
                    className="content"
                    style={{
                      borderLeft:
                        owner.length === 0 ? "3px solid #216c98" : "none",
                    }}
                  >
                    <input
                      className="content1input"
                      onClick={toggleDropdownContent1}
                      value={isOwnerOpen ? ownerSearch : owner}
                      onChange={handleInputContent1Change}
                      readOnly={!isOwnerOpen}
                    />
                    <FontAwesomeIcon icon={faUser} id="ownerimage" />
                    <span className="dropdown-icon" onClick={toggleDropdown}>
                      {isOwnerOpen ? (
                        <FontAwesomeIcon icon={faCaretUp} />
                      ) : (
                        <FontAwesomeIcon icon={faCaretDown} />
                      )}
                    </span>
                    <label className="content1owner">OWNER</label>
                  </div>
                  {isOwnerOpen && (
                    <div
                      className="content1dropdown"
                      style={{ position: "relative", top: "-34px" }}
                    >
                      {filteredOptionsContent1.length === 0 ? (
                        <div className="no-results-contnt1">
                          No results found
                        </div>
                      ) : (
                        filteredOptionsContent1.map((option, index) => (
                          <div
                            key={index}
                            className="dropdown-list-item-content1"
                            onClick={() => selectOptionContent1(option)}
                          >
                            {option}
                          </div>
                        ))
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="containerA2">
              <div id="content3" className="input-container-creataccount">
                <input
                  type="text"
                  className="inputTextA1"
                  value={parentAccount}
                  onChange={(e) => {
                    setParentAccount(e.target.value);
                    hideTooltip(); // Hide the tooltip when user starts typing
                  }}
                  onFocus={showTooltip}
                  onBlur={hideTooltip}
                />
                <label>PARENT ACCOUNT</label>
                <div id="tooltipaccount" className="tooltip">
                  PLEASE ENTER 3 OR MORE CHARACTERS
                </div>
              </div>
            </div>
            <div className="containerA3">
              <div id="content4">
                <input
                  type="text"
                  className="inputTextA1"
                  placeholder
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
                <label>DESCRIPTION</label>
              </div>
            </div>
            <div className="containerA4">
              <div className="dropdown_list" ref={dropdownRef}>
                <div className="dropdown-input-container">
                  <input
                    type="text"
                    className="dropdown-input"
                    value={isRegionOpen ? regionSearch : region}
                    onChange={handleInputChange}
                    onClick={toggleDropdown}
                    readOnly={!isRegionOpen} // Set readOnly attribute based on isRegionOpen
                  />
                  <span className="dropdown-icon" onClick={toggleDropdown}>
                    {isRegionOpen ? (
                      <FontAwesomeIcon icon={faCaretUp} />
                    ) : (
                      <FontAwesomeIcon icon={faCaretDown} />
                    )}
                  </span>
                  <label className="content5label">REGION</label>
                </div>
                {isRegionOpen && (
                  <div className="dropdown-list" id="dropdownlist">
                    {filteredOptions.length === 0 ? (
                      <div className="no-results">No results found</div>
                    ) : (
                      filteredOptions.map((option, index) => (
                        <div
                          key={index}
                          className="dropdown-list-item"
                          onClick={() => selectOption(option)}
                        >
                          {option}
                        </div>
                      ))
                    )}
                  </div>
                )}
              </div>
              <div className="content5Container" ref={dropdownContent5Ref}>
                <div id="content6">
                  <input
                    type="text"
                    className="contentinput6"
                    onClick={toggleDropdownContent5}
                    onChange={handleInputContent5Change}
                    value={isIndustryOpen ? industrySearch : industry}
                    readOnly={!isIndustryOpen} // Set readOnly attribute based on isOpe
                  />
                  <span className="dropdown-icon" onClick={toggleDropdown}>
                    {isIndustryOpen ? (
                      <FontAwesomeIcon icon={faCaretUp} />
                    ) : (
                      <FontAwesomeIcon icon={faCaretDown} />
                    )}
                  </span>
                  <label>INDUSTRY</label>
                </div>
                {isIndustryOpen && (
                  <div className="content5dropdown">
                    {filteredOptionsContent5.length === 0 ? (
                      <div className="no-results-contnt5">No results found</div>
                    ) : (
                      filteredOptionsContent5.map((option, index) => (
                        <div
                          key={index}
                          className="dropdown-list-item-content5"
                          onClick={() => selectOptionContent5(option)}
                        >
                          {option}
                        </div>
                      ))
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="containerA5">
              <div className="content7Container" ref={dropdownContent7Ref}>
                <div id="content7">
                  <input
                    className="content7input"
                    onClick={toggleDropdownContent7}
                    value={isVerticalOpen ? verticalSearch : vertical}
                    onChange={handleInputContent7Change}
                    readOnly={!isVerticalOpen}
                  />

                  <span className="dropdown-icon" onClick={toggleDropdown}>
                    {isVerticalOpen ? (
                      <FontAwesomeIcon icon={faCaretUp} />
                    ) : (
                      <FontAwesomeIcon icon={faCaretDown} />
                    )}
                  </span>
                  <label>VERTICAL</label>
                </div>
                {isVerticalOpen && (
                  <div className="content7dropdown">
                    {filteredOptionsContent7.length === 0 ? (
                      <div className="no-results-contnt7">No results found</div>
                    ) : (
                      filteredOptionsContent7.map((option, index) => (
                        <div
                          key={index}
                          className="dropdown-list-item-content7"
                          onClick={() => selectOptionContent7(option)}
                        >
                          {option}
                        </div>
                      ))
                    )}
                  </div>
                )}
              </div>
              <div id="content8Container" ref={dropdownContent8Ref}>
                <div className="content8">
                  <input
                    className="content8input"
                    onClick={toggleDropdownContent8}
                    value={isTypeOpen ? typeSearch : type}
                    onChange={handleInputContent8Change}
                    readOnly={!isTypeOpen}
                  />

                  <span className="dropdown-icon" onClick={toggleDropdown}>
                    {isTypeOpen ? (
                      <FontAwesomeIcon icon={faCaretUp} />
                    ) : (
                      <FontAwesomeIcon icon={faCaretDown} />
                    )}
                  </span>
                  <label className="content8type">TYPE</label>
                </div>
                {isTypeOpen && (
                  <div className="content8dropdown">
                    {filteredOptionsContent8.length === 0 ? (
                      <div className="no-results-contnt8">No results found</div>
                    ) : (
                      filteredOptionsContent8.map((option, index) => (
                        <div
                          key={index}
                          className="dropdown-list-item-content8"
                          onClick={() => selectOptionContent8(option)}
                        >
                          {option}
                        </div>
                      ))
                    )}
                  </div>
                )}
              </div>
            </div>

            {/* BILLING ADDRESS */}
            <div className="containerA6">
              <div className="left1a">
                <button
                  type="button"
                  className="bbtn"
                  onClick={myFunctionA}
                  id="clickme"
                >
                  <b>
                    BILLING ADDRESS &nbsp; <span className="fa fa-angle-down" />
                  </b>
                </button>
                <div id="billOne" className="hidden">
                  <div className="content9">
                    <div className="street1">
                      <input
                        className="billing"
                        type="text"
                        placeholder
                        value={billingStreet1}
                        onChange={(e) => setBillingStreet1(e.target.value)}
                      />
                      <label>STREET-1</label>
                    </div>
                    {/* </div>  <div class="content9"> */}
                    <div className="street2">
                      <input
                        className="billing"
                        type="text"
                        placeholder
                        value={billingStreet2}
                        onChange={(e) => setBillingStreet2(e.target.value)}
                      />
                      <label>STREET-2</label>
                    </div>
                  </div>
                  <div className="content9">
                    <div className="street1">
                      <input
                        className="billing"
                        type="text"
                        placeholder
                        value={billingCity}
                        onChange={(e) => setBillingCity(e.target.value)}
                      />{" "}
                      <label>CITY</label>
                    </div>
                    {/* </div>  <div class="content9"> */}
                    <div className="street2">
                      <input
                        className="billing"
                        type="text"
                        placeholder
                        value={billingState}
                        onChange={(e) => setBillingState(e.target.value)}
                      />
                      <label>STATE</label>
                    </div>
                  </div>
                  <div className="content9">
                    <div className="street1">
                      <input
                        className="billing"
                        type="text"
                        placeholder
                        value={billingZip}
                        onChange={(e) => setBillingZip(e.target.value)}
                      />{" "}
                      <label>ZIP</label>
                    </div>
                    {/* </div>  <div class="content9"> */}
                    <div className="street2">
                      <input
                        className="billing"
                        type="text"
                        placeholder
                        value={billingCountry}
                        onChange={(e) => setBillingCountry(e.target.value)}
                      />
                      <label>COUNTRY</label>
                    </div>
                  </div>
                  <div className="content9">
                    <input
                      className="billing"
                      type="text"
                      placeholder
                      style={{ marginLeft: "0.8%" }}
                      value={billingPhone}
                      onChange={(e) => setBillingPhone(e.target.value)}
                    />
                    <label>PHONE</label>
                  </div>
                </div>
              </div>
              <div className="right1a">
                <button
                  type="button"
                  onClick={myFunctionB}
                  className="sbtn"
                  id="clikhere"
                >
                  <b>
                    SHIPPING ADDRESS &nbsp;
                    <span className="fa fa-angle-down" />
                  </b>
                </button>
                <div id="ship" className="hidden">
                  <div className="content10">
                    <div className="street1">
                      <input
                        className="billing"
                        type="text"
                        placeholder
                        value={shippingStreet1}
                        onChange={(e) => setShippingStreet1(e.target.value)}
                      />
                      <label id="l1">STREET-1</label>
                    </div>
                    {/* <div class="content10"> */}
                    <div className="street2">
                      {" "}
                      <input
                        className="billing"
                        type="text"
                        placeholder
                        value={shippingStreet2}
                        onChange={(e) => setShippingStreet2(e.target.value)}
                      />
                      <label>STREET-2</label>
                    </div>
                  </div>
                  <div className="content10">
                    <div className="street1">
                      <input
                        className="billing"
                        type="text"
                        placeholder
                        value={shippingCity}
                        onChange={(e) => setShippingCity(e.target.value)}
                      />{" "}
                      <label>CITY</label>
                    </div>
                    {/* </div>  <div class="content10"> */}
                    <div className="street2">
                      <input
                        className="billing"
                        type="text"
                        placeholder
                        value={shippingState}
                        onChange={(e) => setShippingState(e.target.value)}
                      />
                      <label>STATE</label>
                    </div>
                  </div>
                  <div className="content10">
                    <div className="street1">
                      <input
                        className="billing"
                        type="text"
                        placeholder
                        value={shippingZip}
                        onChange={(e) => setShippingZip(e.target.value)}
                      />{" "}
                      <label>ZIP</label>
                    </div>
                    {/* </div>  <div class="content10"> */}
                    <div className="street2">
                      <input
                        className="billing"
                        type="text"
                        placeholder
                        value={shippingCountry}
                        onChange={(e) => setShippingCountry(e.target.value)}
                      />
                      <label>COUNTRY</label>
                    </div>
                  </div>
                  <div className="content10">
                    <input
                      className="billing"
                      type="text"
                      placeholder
                      style={{ marginLeft: "0.7%" }}
                      value={shippingPhone}
                      onChange={(e) => setShippingPhone(e.target.value)}
                    />
                    <label>PHONE</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* tables */}

          {/* --------------opportunities----------------- */}
          <div id="oppopagination">
            <Addinfo />
            <table className="oppotable">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Quote</th>
                  <th>Status</th>
                  <th>Close Date</th>
                  <th>Owner</th>
                  <th>Revenue</th>
                  <th>Margin</th>
                </tr>
              </thead>
              <tbody>{renderTableRows()}</tbody>
            </table>
            <br></br>
            {renderPagination()}
            {/*-------------------- + opportunities------------------ */}
            <div className="plus-oppertunities">
              <a
                href={`./opportunities?data=${encodeURIComponent(
                  JSON.stringify(account_key)
                )}`}
              >
                <i className="fa fa-plus" />
              </a>
            </div>

            {/* ------------delete account------------- */}
            <div className="deleteacc">
              <div className="project">
                <b>PROJECTS</b>
                <p>No Projects</p>
              </div>
              <button
                className="update_account"
                style={{ color: "green" }}
                onClick={handleUpdateAccount}
              >
                {" "}
                UPDATE ACCOUNT
              </button>
              <button
                className="delete_account"
                style={{ color: "red" }}
                onClick={handleDeleteAccount}
              >
                DELETE ACCOUNT
              </button>
            </div>
          </div>
          <div id="save_cancel">
            <button
              className="save_account"
              id="saveAccount"
              style={{ color: "green" }}
              onClick={handleAddAccount}
            >
              ADD ACCOUNT
            </button>
            <button
              className="cancel_account"
              id="cancelAccount"
              style={{ color: "red" }}
              onClick={resetInputFields}
            >
              CANCEL ACCOUNT
            </button>
          </div>
        </div>
      </div>

      {/*---------- accountslisting------------- */}
      <div id="actlist" style={{ display: "none", paddingTop: "68px" }}>
        <div className="acl">
          <b>ACCOUNTS LISTING</b>
        </div>
        <button className="xlbutton" onClick={exportData}>
          <span className="fa fa-file-excel-o" />
        </button>
        <div className="list">
          <table id="tblStocks" cellPadding={0} cellSpacing={0}>
            <tbody>
              <tr className="ellipsis">
                <th>Account Name</th>
                <th>Owner</th>
                <th>Teritory</th>
                <th>Vertical</th>
                <th>Industry</th>
                <th>xcelaerate partner</th>
                <th>Commercial Region</th>
                <th>Commercial Sub Region</th>
                <th>Vat Number</th>
                <th>Delivery Area</th>
              </tr>
              <tr>
                <td>Life HealthCare INC</td>
                <td>A</td>
                <td>35.58</td>
                <td>38.45</td>
                <td>+8.06</td>
                <td>35.58</td>
                <td>38.45</td>
                <td>+8.06</td>
                <td>38.45</td>
                <td>+8.06</td>
              </tr>
              <tr>
                <td>Life HealthCare INC</td>
                <td>A</td>
                <td />
                <td />
                <td>+8.06</td>
                <td>35.58</td>
                <td>38.45</td>
                <td>+8.06</td>
                <td>38.45</td>
                <td>+8.06</td>
              </tr>
              <tr>
                <td>Life HealthCare INC</td>
                <td>A</td>
                <td />
                <td />
                <td>+8.06</td>
                <td>35.58</td>
                <td>38.45</td>
                <td>+8.06</td>
                <td>38.45</td>
                <td>+8.06</td>
              </tr>
              <tr>
                <td>Life HealthCare INC</td>
                <td>A</td>
                <td />
                <td />
                <td>+8.06</td>
                <td>35.58</td>
                <td>38.45</td>
                <td>+8.06</td>
                <td>38.45</td>
                <td>+8.06</td>
              </tr>
            </tbody>
          </table>
          <br />
        </div>
      </div>
    </div>
  );
};

export default Account;
