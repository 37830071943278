import React, { useEffect, useRef } from 'react'
import Navbar from '../../components/Navbar'
import Sidebar from '../../components/Sidebar'
import '../Opportunities/OpportunitiesData.css'
import { myFunctionA, myFunctionB } from '../Accounts/AccountFlex'
import { exportData1, myfunctionData, handleOpenCloseOppo } from './OpportunitiesDatajs'
import HelpRequest from '../../components/HelpRequest.jsx'
import { useState } from 'react'
import {
  handleClickFiles, displayFileName, handleClickBidTeam, handleClickNotes, handleClickExternal,
  handleClickTimestamp
} from '../Accounts/AccountSidebar'
import { handleClickInternal, copyText, handleClickLink, copyLink, handleOpenClose } from '../Accounts/AccountSidebar'
import { useLocation } from 'react-router-dom';
import { useAuthContext } from "../../hooks/useAuthContext";
import { event } from 'jquery'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import the styles
import { faCaretUp, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const OpportunitiesData = () => {

  const { user } = useAuthContext();
  console.log(user);
  // const [closeDate, setCloseDate] = useState(new Date()); // Initial date for CLOSE
  // const [startDate, setStartDate] = useState(new Date()); // Initial date for START


  const { state: row } = useLocation();
  const oppData = row.row;



  const opportunityId = oppData.opportunity_id;



  //useStates for saving updated values into database from frontend(data has been get from database)

  const [opportunityName, setOpportunityName] = useState('');
  const [parentOpportunity, setParentOpportunity] = useState('');
  const [account, setAccount] = useState('');
  const [netPrice, setNetPrice] = useState('');
  const [margin, setMargin] = useState('');
  const [cost, setCost] = useState('');
  // const [stage, setStage] = useState('');
  // const [permissionType, setPermissionType] = useState('');
  const [probability, setProbability] = useState('');
  const [hours, setHours] = useState('');
  // const [close, setClose] = useState('');
  // const [start, setStart] = useState('');
  const [startDate, setStartDate] = useState('');
  const [closeDate, setCloseDate] = useState('');
  const [duration_weeks, setDuration_weeks] = useState('');
  const [list_price, setListPrice] = useState('');
  const [owner, setOwner] = useState('');
  // const [region, setRegion] = useState('');
  // const [vertical, setVertical] = useState('');
  // const [practice, setPractice] = useState('');
  // const [currency, setCurrency] = useState('');
  // const [org, setOrg] = useState('');
  const [opportunity_type, setOpportunity_Type] = useState('');

  useEffect(() => {
    setOpportunityName(oppData.opportunity_name);
    setParentOpportunity(oppData.parentOpportunity)
    setAccount(oppData.account);
    setNetPrice(oppData.net_price);
    setMargin(oppData.margin);
    setCost(oppData.cost);
    setStage(oppData.stage);
    setPermissionType(oppData.permissionType)
    setProbability(oppData.probability);
    setHours(oppData.hours);
    setCloseDate(oppData.close);
    setStartDate(oppData.start);
    setDuration_weeks(oppData.duration_weeks);
    setOwner(oppData.owner);
    setRegion(oppData.region);
    setVertical(oppData.vertical);
    setPractice(oppData.practice);
    setCurrency(oppData.currency);
    setOrg(oppData.org);
    setOpportunity_Type(oppData.opportunity_type);
  }, [oppData])


  const handleUpdateOpportunity = () => {


    fetch(`http://localhost:4001/api/opportunity/update/${oppData.opportunity_id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user.token}`,
      },
      body: JSON.stringify({
        opportunity_name: opportunityName,
        net_price: netPrice,
        margin: margin,
        cost: cost,
        stage: stage,
        probability: probability,
        hours: hours,
        close: closeDate,
        start: startDate,
        duration_weeks: duration_weeks,
        owner: owner,
        region: region,
        vertical: vertical,
        practice: practice,
        currency: currency,
        org: org,
        opportunity_type: opportunity_type,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Opportunity updated successfully!', data);
        window.location.href = '/account'
      })
      .catch((error) => {
        console.log('Error updating opportunity:', error);
      });

  };

  const deleteOpportunity = () => {


    fetch(`http://localhost:4001/api/opportunity/delete/${opportunityId}`, {

      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user.token}`,
      },

    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Opportunity Deleted successfully!', data);
        window.location.href = '/account';
      })
      .catch((error) => {
        console.log('Error Deleting opportunity:', error);
      });

  };

  // ------------------------------------------------------------------------------------------


  const [isOpenStage, setIsOpenStage] = useState(false);
  const [optionsStage, setOptionsContent03] = useState([
    "QUALIFY ",
    "SCOPE ",
    "GO NO-GO ",
    "VALIDATE SOLUTION ",
    "BUSINESS CASE ",
    "NEGOTIATE & CLOSE ",
    "DISCOVERY ",
    "CLOSED-NO SALE",
  ]);

  const [isOpenPermissionType, setIsOpenPermissionType] = useState(false);
  const [optionsPermissionType, setOptionsPermissionType] = useState([
    "CONFIDENTIAL",
    "DEFAULT",
    "PERMISSION TYPE",
  ]);

  const [isOpenRegion, setIsOpenRegion] = useState(false);
  const [optionsRegion, setOptionsContent05] = useState([
    "AP ",
    "EMEA ",
    "LA ",
    "NA ",
  ]);

  const [isOpenVertical, setIsOpenVertical] = useState(false);
  const [optionsVertical, setOptionsContent052] = useState([
    "BANKING ",
    "CHEMICALS",
    "CONSTRUCTION & ENGG ",
    "CONSULTING SERVICE ",
    "CONSUMER PRODUCTS ",
    "EDUCATION ",
    "ENTERTAINMENT & LEISURE",
    "FINANCIAL SERVICES",
  ]);

  const [isOpenPractice, setIsOpenPractice] = useState(false);
  const [optionsPractice, setOptionsContent053] = useState([
    "DBA",
    "DSOM",
    "EDUCATION",
    "IZOT",
  ]);

  const [isOpenCurrency, setIsOpenCurrency] = useState(false);
  const [optionsCurrency, setOptionsContent06] = useState([
    "Afghan Afghani ",
    "Albanian Lek ",
    "Algerian Dinar ",
    "Angolan Kwanza ",
    "Argentine Peso",
    "Armenian Dram",
    "Aruban Florin",
    "Australian Dollar",
    "Azerbaijani Manat",
    "Bahamian Dollar",
    "Bahraini Dinar",
    "Bangladeshi Taka",
    "Barbadian Dollar",
    "Belarusian Ruble",
    "Belgian Franc",
    "Belize Dollar",
    "Bermudan Dollar",
    "Bhutanese Ngultrum",
    "Bitcoin",
    "Bolivian",
    "Bosnia-Herzegovina",
    "Botswanan Pula",
    "Brazilian Real",
    "British Pound Sterling",
    "Brunei Dollar",
    "Bulgarian Lev",
    "Burundian Franc",
  ]);

  const [isOpenOrg, setIsOpenOrg] = useState(false);
  const [optionsOrg, setOptionsContent061] = useState([
    "AFRICA",
    "ALL OTHER AP",
    "AP-ALL OTHER",
    "AP-AUS/JP",
    "AP-CHINA",
    "AP-INDIA",
    "ARGENTINA",
    "AUSTRALIA",
  ]);

  const [isOpenOpportunityType, setIsOpenOpportunityType] = useState(false);
  const [optionsOpportunityType, setOptionsContent062] = useState([
    "CONSULTING SERVICES OPPORTUNITY",
    "EDUCATIONAL SERVICES OPPORTUNITY",

  ]);
  const [stage, setStage] = useState("");
  const [searchTermStage, setSearchTermStage] = useState("");

  const [PermissionType, setPermissionType] = useState("");
  const [searchTermPermissionType, setSearchTermPermissionType] =useState("");

  const [region, setRegion] = useState("");
  const [searchTermRegion, setSearchTermRegion] = useState("");

  const [vertical, setVertical] = useState("");
  const [searchTermVertical, setSearchTermVertical] = useState("");

  const [practice, setPractice] = useState("");
  const [searchTermPractice, setSearchTermPractice] = useState("");

  const [currency, setCurrency] = useState("");
  const [searchTermCurrency, setSearchTermCurrency] = useState("");

  const [org, setOrg] = useState("");
  const [searchTermOrg, setSearchTermOrg] = useState("");

  const [opportunityType, setOpportunityType] = useState("");
  const [searchTermOpportunityType, setSearchTermOpportunityType] = useState("");

  const toggleDropdownStage = () => {
    setIsOpenStage(!isOpenStage);
    if (!isOpenStage) {
      setSearchTermStage("");
    }
  };

  const toggleDropdownPermissionType = () => {
    setIsOpenPermissionType(!isOpenPermissionType);
    if (!isOpenPermissionType) {
      setSearchTermPermissionType("");
    }
  };
  const toggleDropdownRegion = () => {
    setIsOpenRegion(!isOpenRegion);
    if (!isOpenRegion) {
      setSearchTermRegion("");
    }
  };

  const toggleDropdownVertical = () => {
    setIsOpenVertical(!isOpenVertical);
    if (!isOpenVertical) {
      setSearchTermVertical("");
    }
  };

  const toggleDropdownPractice = () => {
    setIsOpenPractice(!isOpenPractice);
    if (!isOpenPractice) {
      setSearchTermPractice("");
    }
  };

  const toggleDropdownCurrency = () => {
    setIsOpenCurrency(!isOpenCurrency);
    if (!isOpenCurrency) {
      setSearchTermCurrency("");
    }
  };

  const toggleDropdownOrg = () => {
    setIsOpenOrg(!isOpenOrg);
    if (!isOpenOrg) {
      setSearchTermOrg("");
    }
  };

  const toggleDropdownOpportunityType = () => {
    setIsOpenOpportunityType(!isOpenOpportunityType);
    if (!isOpenOpportunityType) {
      setSearchTermOpportunityType("");
    }
  };


  const handleInputChangeStage = (e) => {
    const inputValueStage = e.target.value;
    setSearchTermStage(inputValueStage);

    if (!isOpenStage) {
      setIsOpenStage(true);
    }
  };

  const handleInputChangePermissionType = (e) => {
    const inputValuePermissionType = e.target.value;
    setSearchTermPermissionType(inputValuePermissionType);
    if (!isOpenPermissionType) {
      setIsOpenPermissionType(true);
    }
  };

  const handleInputChangeRegion = (e) => {
    const inputValueRegion = e.target.value;
    setSearchTermRegion(inputValueRegion);

    if (!isOpenRegion) {
      setIsOpenRegion(true);
    }
  };

  const handleInputChangeVertical = (e) => {
    const inputValueVertical = e.target.value;
    setSearchTermVertical(inputValueVertical);

    if (!isOpenVertical) {
      setIsOpenVertical(true);
    }
  };

  const handleInputChangePractice = (e) => {
    const inputValueContent053 = e.target.value;
    setSearchTermPractice(inputValueContent053);

    if (!isOpenPractice) {
      setIsOpenPractice(true);
    }
  };

  const handleInputChangeCurrency = (e) => {
    const inputValueCurrency = e.target.value;
    setSearchTermCurrency(inputValueCurrency);

    if (!isOpenCurrency) {
      setIsOpenCurrency(true);
    }
  };

  const handleInputChangeOrg = (e) => {
    const inputValueOrg = e.target.value;
    setSearchTermOrg(inputValueOrg);

    if (!isOpenOrg) {
      setIsOpenOrg(true);
    }
  };

  const handleInputChangeOpportunityType = (e) => {
    const inputValueOpportunityType = e.target.value;
    setSearchTermOpportunityType(inputValueOpportunityType);

    if (!isOpenOpportunityType) {
      setIsOpenOpportunityType(true);
    }
  };

  const selectOptionStage = (option) => {
    setStage(option);
    setSearchTermStage(option);
    setIsOpenStage(false);
  };

  const selectOptionPermissionType = (option) => {
    setPermissionType(option);
    setSearchTermPermissionType(option);
    setIsOpenPermissionType(false);
  };

  const selectOptionRegion = (option) => {
    setRegion(option);
    setSearchTermRegion(option);
    setIsOpenRegion(false);
  };
  const selectOptionVertical = (option) => {
    setVertical(option);
    setSearchTermVertical(option);
    setIsOpenVertical(false);
  };

  const selectOptionPractice = (option) => {
    setPractice(option);
    setSearchTermPractice(option);
    setIsOpenPractice(false);
  };

  const selectOptionCuirrency = (option) => {
    setCurrency(option);
    setSearchTermCurrency(option);
    setIsOpenCurrency(false);
  };

  const selectOptionOrg = (option) => {
    setOrg(option);
    setSearchTermOrg(option);
    setIsOpenOrg(false);
  };

  const selectOptionOpportunityType = (option) => {
    setOpportunityType(option);
    setSearchTermOpportunityType(option);
    setIsOpenOpportunityType(false);
  };

  const filteredOptionsStage = optionsStage.filter((option) =>
    option.toLowerCase().includes(searchTermStage.toLowerCase())
  );

  const filteredOptionsPermissionType = optionsPermissionType.filter((option) =>
      option.toLowerCase().includes(searchTermPermissionType.toLowerCase())
  );

  const filteredOptionsRegion = optionsRegion.filter((option) =>
    option.toLowerCase().includes(searchTermRegion.toLowerCase())
  );
  const filteredOptionsVertical = optionsVertical.filter((option) =>
    option.toLowerCase().includes(searchTermVertical.toLowerCase())
  );

  const filteredOptionsPractice = optionsPractice.filter((option) =>
    option.toLowerCase().includes(searchTermPractice.toLowerCase())
  );

  const filteredOptionsCurrency = optionsCurrency.filter((option) =>
    option.toLowerCase().includes(searchTermCurrency.toLowerCase())
  );

  const filteredOptionsOrg = optionsOrg.filter((option) =>
    option.toLowerCase().includes(searchTermOrg.toLowerCase())
  );

  const filteredOptionsOpportunityType = optionsOpportunityType.filter((option) =>
    option.toLowerCase().includes(searchTermOpportunityType.toLowerCase())
  );

  const dropdownRefStage = useRef(null);
  const dropdownRefPermissionType = useRef(null);
  const dropdownRefRegion = useRef(null);
  const dropdownRefVertical = useRef(null);
  const dropdownRefPractice = useRef(null);
  const dropdownRefCurrency = useRef(null);
  const dropdownRefOrg = useRef(null);
  const dropdownRefOpportunityType = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        dropdownRefStage.current &&
        !dropdownRefStage.current.contains(event.target)
      ) {
        setIsOpenStage(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        dropdownRefPermissionType.current &&
        !dropdownRefPermissionType.current.contains(event.target)
      ) {
        setIsOpenPermissionType(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        dropdownRefRegion.current &&
        !dropdownRefRegion.current.contains(event.target)
      ) {
        setIsOpenRegion(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        dropdownRefVertical.current &&
        !dropdownRefVertical.current.contains(event.target)
      ) {
        setIsOpenVertical(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        dropdownRefPractice.current &&
        !dropdownRefPractice.current.contains(event.target)
      ) {
        setIsOpenPractice(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        dropdownRefCurrency.current &&
        !dropdownRefCurrency.current.contains(event.target)
      ) {
        setIsOpenCurrency(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        dropdownRefOrg.current &&
        !dropdownRefOrg.current.contains(event.target)
      ) {
        setIsOpenOrg(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        dropdownRefOpportunityType.current &&
        !dropdownRefOpportunityType.current.contains(event.target)
      ) {
        setIsOpenOpportunityType(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  // ------------------------------------------------------------------------------------------

// const [opportunityName, setOpportunityName] = useState('');
const [displayErrorMessageData, setDisplayErrorMessageData] = useState(false);
const [displayOpportunityDataName, setDisplayOpportunityDataName] = useState(true);
const contentD1Input = document.querySelector(".contentD1Input");

const handleBlur = () => {
  const trimmedValue = opportunityName.trim();
  if (trimmedValue === "") {
    setDisplayErrorMessageData(true);
    setDisplayOpportunityDataName(false);
    contentD1Input.style.outlineColor = "red";
    contentD1Input.style.border = " 2px solid red";
  } else {
    setDisplayErrorMessageData(false);
    setDisplayOpportunityDataName(true);
    contentD1Input.style.outlineColor = "#045679";
    contentD1Input.style.border = " 1px solid #ccc";
  }
};


  return (
    <div>
      <Navbar />
      <Sidebar />
      <div className="bread">
        <ul className="breadcrumbs">
          <li className="breadcrumbs--item">
            <a href="./home" className="breadcrumbs--link breadcrumbs" style={{ display: 'inline', textDecoration: 'none' }}>HOME</a>
          </li>
          <li className="breadcrumbs--item">
            <a href="./account" className="breadcrumbs--link breadcrumbs--link--active">ACCOUNTS</a>
          </li>
          <li className="breadcrumbs--item">
            <a href="./account" className="breadcrumbs--link breadcrumbs--link--active">{opportunityName}</a>
          </li>
        </ul>
        <hr className="hr" />
      </div>
      <HelpRequest />
      <div id="mainpage">
        <div className="opportunitydata">

          <div id="opportunitiesheader1"><a>OPPORTUNITY</a></div>
          <div className="dataleft">
            <div className="containerD1">
              <div id="contentD1"  style={{
                  borderLeft: opportunityName.trim() ? '1px solid #ccc' : '2px solid #216c98',
                }}>
                <input
                className='contentD1Input'
                  type="text"
                  value={opportunityName}
                  onChange={(e) => setOpportunityName(e.target.value)}
                  onBlur={handleBlur}
                />
                  {displayOpportunityDataName && (
                <label className="opportunityname">OPPORTUNITY</label>
              )}
              {displayErrorMessageData && (
                <label className="errormsg"> OPPORTUNITY NAME IS REQUIRED</label>
              )}
              </div>
            </div>
            <div className="containerD2">
              <div id="contentD2">
                <input type="text"
                  value={parentOpportunity}
                  onChange={(e) => setParentOpportunity(e.target.value)}
                />
                <label>PARENT OPPORTUNITY</label>
              </div>
            </div>
            <div className="containerD3">
              <div id="contentD3">
                <input className
                  type="text"
                  value={account}
                  onChange={(e) => setAccount(e.target.value)}
                />
                <label>ACCOUNT</label>
              </div>
              <div id="contentD3A" ref={dropdownRefOpportunityType}>

                <div id="contentO62" >
                  <input
                    type="text"
                    className="container06input2"
                    value={
                      isOpenOpportunityType
                        ? searchTermOpportunityType
                        : opportunityType
                    }
                    onChange={handleInputChangeOpportunityType}
                    onClick={toggleDropdownOpportunityType}
                    readOnly={!isOpenOpportunityType} // Set readOnly attribute based on isOpen
                  />
                  <span
                    className="dropdown-icon-content062"
                    onClick={toggleDropdownOpportunityType}
                  >
                    {isOpenOpportunityType ? (
                      <FontAwesomeIcon icon={faCaretUp} />
                    ) : (
                      <FontAwesomeIcon icon={faCaretDown} />
                    )}
                  </span>
                  <label>OPPRTUNITY TYPE</label>
                </div>
                {isOpenOpportunityType && (
                  <div
                    className="dropdown-list-content062"
                    id="dropdownlistcontent062"
                  >
                    {filteredOptionsOpportunityType.length === 0 ? (
                      <div className="no-results">No results found</div>
                    ) : (
                      filteredOptionsOpportunityType.map((option, index) => (
                        <div
                          key={index}
                          className="dropdown-list-item-content062"
                          onClick={() => selectOptionOpportunityType(option)}
                        >
                          {option}
                        </div>
                      ))
                    )}
                  </div>
                )}

              </div>
            </div>
            <div className="containerD4">
              <div className="containt03container" ref={dropdownRefStage}>
                <div id="contentO3">
                  <input
                    type="text"
                    className="container03input"
                    value={
                      isOpenStage
                        ? searchTermStage
                        : stage
                    }
                    onChange={handleInputChangeStage}
                    onClick={toggleDropdownStage}
                    readOnly={!isOpenStage} // Set readOnly attribute based on isOpen
                  />
                  <span
                    className="dropdown-icon-content03"
                    onClick={toggleDropdownStage}
                  >
                    {isOpenStage ? (
                      <FontAwesomeIcon icon={faCaretUp} />
                    ) : (
                      <FontAwesomeIcon icon={faCaretDown} />
                    )}
                  </span>

                  <label>STAGE</label>
                </div>
                {isOpenStage && (
                  <div
                    className="dropdown-list-content03"
                    id="dropdownlistcontent03"
                  >
                    {filteredOptionsStage.length === 0 ? (
                      <div className="no-results">No results found</div>
                    ) : (
                      filteredOptionsStage.map((option, index) => (
                        <div
                          key={index}
                          className="dropdown-list-item-content03"
                          onClick={() => selectOptionStage(option)}
                        >
                          {option}
                        </div>
                      ))
                    )}
                  </div>
                )}
              </div>
              <div id="contentD4PermissionType" ref={dropdownRefPermissionType}>
<div id="contentD4PermissionType">
  <input
    type="text"
    className="contentD4InputPermissionType"
    value={
      isOpenPermissionType
        ? searchTermPermissionType
       : PermissionType
    }
    onChange={handleInputChangePermissionType}
    onClick={toggleDropdownPermissionType}
    readOnly={!isOpenPermissionType} // Set readOnly attribute based on isOpen
  />
  <span
    className="dropdown-icon-PermissionType"
    onClick={toggleDropdownPermissionType}
  >
    {isOpenPermissionType ? (
      <FontAwesomeIcon icon={faCaretUp} />
    ) : (
      <FontAwesomeIcon icon={faCaretDown} />
    )}
  </span>
  <label>PERMISSION TYPE</label>
</div>
{isOpenPermissionType && (
  <div
    className="dropdown-list-PermissionType"
    id="dropdownlistcontentD4"
  >
    {filteredOptionsPermissionType.length === 0 ? (
      <div className="no-results">No results found</div>
    ) : (
      filteredOptionsPermissionType.map((option, index) => (
        <div
          key={index}
          className="dropdown-list-item-PermissionType"
          onClick={() => selectOptionPermissionType(option)}
        >
          {option}
        </div>
      ))
    )}
  </div>
)}
</div>

            </div>
            <div className="containerD5">
              <div id="contentD5">
                <input type="text" value={probability} onChange={(e) => setProbability(e.target.value)} />
                <label>PROBABILITY</label>
              </div>


              <div id="contentD5">
                <DatePicker
                  // selected={closeDate}
                  onChange={date => setCloseDate(date)}
                  onFocus={() => setCloseDate(new Date())}
                  dateFormat="MM/dd/yyyy"
                  value={closeDate}
                />
                <label>CLOSE</label>
              </div>
              <div id="contentD5">
                <DatePicker
                  // selected={startDate}
                  onChange={date => setStartDate(date)}
                  onFocus={() => setStartDate(new Date())}
                  dateFormat="MM/dd/yyyy"
                  value={startDate}
                />
                <label>START</label>
              </div>


            </div>
          </div>
          <div className="dataright">
            <div className="containerD6">
              <div id="contentD6">
                <input className type="number" min={0} placeholder="$0.00" value={netPrice} onChange={(e) => setNetPrice(e.target.value)} />
                <label>NET PRICE</label>
              </div>
              <div id="contentD6">
                <input className type="number" min="0%" placeholder="0%" value={margin} onChange={(e) => setMargin(e.target.value)} />
                <label>MARGIN</label>
              </div>
            </div>
            <div className="containerD7">
              <div id="contentD7">
                <input className type="number" min={0} placeholder="$0.00" value={cost} onChange={(e) => setCost(e.target.value)} />
                <label>COST</label>
              </div>
              <div id="contentD7">
                <input className type="number" min="0%" placeholder value={duration_weeks} onChange={(e) => setDuration_weeks(e.target.value)} />
                <label>WEEKS</label>
              </div>
            </div>
            <div className="containerD8">
              <div id="contentD8">
                <input className type="number" min={0} placeholder />
                <label>LIST PRICE</label>
              </div>
              <div id="contentD8">
                <input className type="number" min="0%" placeholder />
                <label>DISCOUNT</label>
              </div>
            </div>
            <div className="containerD9">
              <div id="contentD9">
                <input className type="number" min={0} placeholder value={hours} onChange={(e) => setHours(e.target.value)} />
                <label>HOURS</label>
              </div>
              <div id="contentD9">
                <input className type="number" min="0%" placeholder />
                <label>AVG RATE</label>
              </div>
            </div>
            <div className="containerD10">
              <div id="contentO61" ref={dropdownRefOrg}>
                <div id="contentO61" >
                  <input
                    type="text"
                    className="container06input1"
                    value={
                      isOpenOrg
                        ? searchTermOrg
                        : org
                    }
                    onChange={handleInputChangeOrg}
                    onClick={toggleDropdownOrg}
                    readOnly={!isOpenOrg} // Set readOnly attribute based on isOpen
                  />
                  <span
                    className="dropdown-icon-content061"
                    onClick={toggleDropdownOrg}
                  >
                    {isOpenOrg ? (
                      <FontAwesomeIcon icon={faCaretUp} />
                    ) : (
                      <FontAwesomeIcon icon={faCaretDown} />
                    )}
                  </span>
                  <label>ORG</label>
                </div>
                {isOpenOrg && (
                  <div
                    className="dropdown-list-content061"
                    id="dropdownlistcontent061">

                    {filteredOptionsOrg.length === 0 ? (
                      <div className="no-results">No results found</div>
                    ) : (
                      filteredOptionsOrg.map((option, index) => (
                        <div
                          key={index}
                          className="dropdown-list-item-content061"
                          onClick={() => selectOptionOrg(option)}
                        >
                          {option}
                        </div>
                      ))
                    )}
                  </div>
                )}
              </div>

              <div id="contentD10">
                <div className="content6container1" ref={dropdownRefCurrency}>
                  <div id="contentO6">
                    <input
                      type="text"
                      className="container06input"
                      value={
                        isOpenCurrency
                          ? searchTermCurrency
                          : currency
                      }
                      onChange={handleInputChangeCurrency}
                      onClick={toggleDropdownCurrency}
                      readOnly={!isOpenCurrency} // Set readOnly attribute based on isOpen
                    />
                    <span
                      className="dropdown-icon-content06"
                      onClick={toggleDropdownCurrency}
                    >
                      {isOpenCurrency ? (
                        <FontAwesomeIcon icon={faCaretUp} />
                      ) : (
                        <FontAwesomeIcon icon={faCaretDown} />
                      )}
                    </span>
                    <label>CURRENCY</label>
                  </div>
                  {isOpenCurrency && (
                    <div
                      className="dropdown-list-content06"
                      id="dropdownlistcontent06"
                    >
                      {filteredOptionsCurrency.length === 0 ? (
                        <div className="no-results">No results found</div>
                      ) : (
                        filteredOptionsCurrency.map((option, index) => (
                          <div
                            key={index}
                            className="dropdown-list-item-content06"
                            onClick={() => selectOptionCuirrency(option)}
                          >
                            {option}
                          </div>
                        ))
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

        </div>
        {/*OPPORTUNITY DETAILS  */}
        <div className="opportunitydetails">
          <form action="#">
            <div className="leftdetail">
              <button type="button" className="bbtndetails" onClick={myfunctionData} id="clickmedetails">
                <b>DETAILS &nbsp; <span className="fa fa-angle-down" /></b></button>
              <div id="oppodetails" className="hidden1">
                <div className="containerD11">
                  <div id="contentD11">
                    <textarea placeholder="Enter Description" defaultValue={""} />
                    <label>DESCRIPTION</label>
                  </div>
                  <div className="containerDleft">
                    <div id="contentD12">
                      <input type="text" value={owner} onChange={(e) => setOwner(event.target.value)} />
                      <label>OWNER</label>
                    </div>
                    <div id="contentD12">
                      <select>
                        <option selected disabled />
                        <option>NO RESULT FOUND</option>
                      </select>
                      <label>DELIVERY MANAGER</label>
                    </div>
                    <div id="contentD13">
                      <input type="text" onfocus="(this.type='date')" />
                      <label>DUE DATE</label>
                    </div>
                    <div id="contentD14">
                      <select>
                        <option selected disabled />
                        <option>REQUESTED</option>
                        <option>IN PROGRESS</option>
                        <option>ON HOLD</option>
                        <option>SUBMITTED</option>
                        <option>REJECTED</option>
                        <option>APPROVED</option>
                      </select>
                      <label>STATUS</label>
                    </div>
                  </div>
                  <div className="containerDright">
                    <div className="content5Container1" ref={dropdownRefRegion}>
                      <div id="contentO5">
                        {/* <img className="avatar__image" src="../src/pages/images/avtar.jpg" /> */}
                        <input
                          type="text"
                          className="container05input"
                          value={
                            isOpenRegion
                              ? searchTermRegion
                              : region
                          }
                          onChange={handleInputChangeRegion}
                          onClick={toggleDropdownRegion}
                          readOnly={!isOpenRegion} // Set readOnly attribute based on isOpen
                        />
                        <span
                          className="dropdown-icon-content05"
                          onClick={toggleDropdownRegion}
                        >
                          {isOpenRegion ? (
                            <FontAwesomeIcon icon={faCaretUp} />
                          ) : (
                            <FontAwesomeIcon icon={faCaretDown} />
                          )}
                        </span>

                        <label>REGION</label>
                      </div>
                      {isOpenRegion && (
                        <div
                          className="dropdown-list-content05"
                          id="dropdownlistcontent05"
                        >
                          {filteredOptionsRegion.length === 0 ? (
                            <div className="no-results">No results found</div>
                          ) : (
                            filteredOptionsRegion.map((option, index) => (
                              <div
                                key={index}
                                className="dropdown-list-item-content05"
                                onClick={() => selectOptionRegion(option)}
                              >
                                {option}
                              </div>
                            ))
                          )}
                        </div>
                      )}

                    </div>

                    <div id="content5Container2" ref={dropdownRefVertical}>
                      <div id="contentO52">
                        <input
                          type="text"
                          className="container052input2"
                          value={
                            isOpenVertical
                              ? searchTermVertical
                              : vertical
                          }
                          onChange={handleInputChangeVertical}
                          onClick={toggleDropdownVertical}
                          readOnly={!isOpenVertical} // Set readOnly attribute based on isOpen
                        />
                        <span
                          className="dropdown-icon-content052"
                          onClick={toggleDropdownVertical}
                        >
                          {isOpenVertical ? (
                            <FontAwesomeIcon icon={faCaretUp} />
                          ) : (
                            <FontAwesomeIcon icon={faCaretDown} />
                          )}
                        </span>
                        <label>VERTICAL</label>
                      </div>
                      {isOpenVertical && (
                        <div
                          className="dropdown-list-content052"
                          id="dropdownlistcontent052"
                        >
                          {filteredOptionsVertical.length === 0 ? (
                            <div className="no-results">No results found</div>
                          ) : (
                            filteredOptionsVertical.map((option, index) => (
                              <div
                                key={index}
                                className="dropdown-list-item-content052"
                                onClick={() => selectOptionVertical(option)}
                              >
                                {option}
                              </div>
                            ))
                          )}
                        </div>
                      )}
                    </div>
                    <div id="contentD16">
                      <div className="content5Container3" ref={dropdownRefPractice}>
                        <div id="contentO53">
                          <input
                            type="text"
                            className="container05input3"
                            value={
                              isOpenPractice
                                ? searchTermPractice
                                : practice
                            }
                            onChange={handleInputChangePractice}
                            onClick={toggleDropdownPractice}
                            readOnly={!isOpenPractice} // Set readOnly attribute based on isOpen
                          />
                          <span
                            className="dropdown-icon-content053"
                            onClick={toggleDropdownPractice}
                          >
                            {isOpenPractice ? (
                              <FontAwesomeIcon icon={faCaretUp} />
                            ) : (
                              <FontAwesomeIcon icon={faCaretDown} />
                            )}
                          </span>

                          <label>PRACTICE</label>
                        </div>
                        {isOpenPractice && (
                          <div
                            className="dropdown-list-content053"
                            id="dropdownlistcontent053"
                          >
                            {filteredOptionsPractice.length === 0 ? (
                              <div className="no-results">No results found</div>
                            ) : (
                              filteredOptionsPractice.map((option, index) => (
                                <div
                                  key={index}
                                  className="dropdown-list-item-content053"
                                  onClick={() => selectOptionPractice(option)}
                                >
                                  {option}
                                </div>
                              ))
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                    <div id="contentD17">
                      <select required>
                        <option selected disabled />
                        <option value>BMC DEAL REVIEW REPORT</option>
                        <option value>BMC DEAL REVIEW REPORT-MULTI WORKSTREAM/CCO</option>
                        <option value>BMC ENGAGEMENT SUMMARY</option>
                        <option value>BMC EXPERT SERVICES SOW</option>
                        <option value>BMC FRAMEWORK SOW-SINGLE WORKSTREAM </option>
                        <option value>BMC SURVEY REVIEW-CTM IMPL.</option>
                        <option value>EDU DIFF REGION</option>
                        <option value>EDUCATION APPROVAL TEMPLATE</option>
                        <option value>EDUCATION ORDER SUMMARY DOCUMENT</option>
                        <option value>EDUCATION QUOTE</option>
                        <option value>GARY TEST</option>
                        <option value>ORDER SERVICES OPENAIR REPORT</option>
                      </select>
                      <label>TEMPLATE QUOTE</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        {/* billing and shipping address in opportunitydata */}
        <div className="containerBS">
          <div className="left1" onClick={myFunctionA}> <button type="button" className="bbtn" id="clickme">
            <b>BILLING ADDRESS &nbsp; <span className="fa fa-angle-down" /></b></button>
            <div id="billOne" className="hidden">
              <div className="content9BS">
                <div className="street1BS">
                  <input className="billingBS" type="text" placeholder required />
                  <label>STREET-1</label>
                </div>
                {/* </div>  <div class="content9BS"> */}
                <div className="street2BS">
                  <input className="billingBS" type="text" placeholder required />
                  <label>STREET-2</label>
                </div>
              </div>
              <div className="content9BS">
                <div className="street1BS">
                  <input className="billingBS" type="text" placeholder required />
                  <label>CITY</label>
                </div>
                {/* </div>  <div class="content9BS"> */}
                <div className="street2BS">
                  <input className="billingBS" type="text" placeholder required />
                  <label>STATE</label>
                </div>
              </div>
              <div className="content9BS">
                <div className="street1BS">
                  <input className="billingBS" type="number" placeholder required /> <label>ZIP</label>
                </div>
                {/* </div>  <div class="content9BS"> */}
                <div className="street2BS">
                  <input className="billingBS" type="text" placeholder required />
                  <label>COUNTRY</label>
                </div>
              </div>
              <div className="content9BS"> <input id="pnoBS" className="billingBS" type="number" placeholder style={{ marginLeft: '0.9%' }} required />
                <label>PHONE</label>
              </div>
            </div>
          </div>
          <div className="right1"> <button type="button" onClick={myFunctionB} className="sbtn" id="clikhere">
            <b>SHIPPING ADDRESS &nbsp;<span className="fa fa-angle-down" /></b></button>
            <div id="ship" className="hidden">
              <div className="content10BS">
                <div className="street1BS">
                  <input className="billingBS" type="text" placeholder required />
                  <label id="l1">STREET-1</label>
                </div>
                {/* <div class="content10BS"> */}
                <div className="street2BS"> <input className="billingBS" type="text" placeholder required />
                  <label>STREET-2</label>
                </div>
              </div>
              <div className="content10BS">
                <div className="street1BS">
                  <input className="billingBS" type="text" placeholder required /> <label>CITY</label>
                </div>
                {/* </div>  <div class="content10BS"> */}
                <div className="street2BS">
                  <input className="billingBS" type="text" placeholder required />
                  <label>STATE</label>
                </div>
              </div>
              <div className="content10BS">
                <div className="street1BS">
                  <input className="billingBS" id="zip" type="number" placeholder required />
                  <label>ZIP</label>
                </div>
                {/* </div>  <div class="content10BS"> */}
                <div className="street2BS">
                  <input className="billingBS" type="text" placeholder required />
                  <label>COUNTRY</label>
                </div>
              </div>
              <div className="content10BS"> <input id="pnoBS" className="billingBS" type="number" placeholder style={{ marginLeft: '0.9%' }} required />
                <label>PHONE</label>
              </div>
            </div>
          </div>
        </div>
        {/* opportunity quote table */}
        <div className="total">
          <div className="downloadandreload">
            <div className="downloadquotes">
              <button className="xlbutton1" onClick={exportData1}>
                <span className="fa fa-file-excel-o" /></button>
              <div>
                <label>DOWNLOAD</label>
              </div>
            </div>
            <div className="quoteshead">
              <b>QUOTES</b>
            </div>
            <div className="reload">
              <span className="fa fa-refresh" />
              <div>
                <label>RELOAD</label>
              </div>
            </div>
          </div>
        </div>
        <div className="listquotes">
          <form>
            <table id="tblStocksQuotes" cellPadding={0} cellSpacing={0}>
              <tbody><tr className="ellipsisquotes">
                <th>PRIMARY</th>
                <th>STATUS</th>
                <th>QUOTE</th>
                <th>DESCRIPTION</th>
                <th>LAST EDITED</th>
                <th>BY</th>
                <th>NET PRICE</th>
                <th>MARGIN</th>
              </tr>
                <tr>
                  <td>1</td>
                  <td>2</td>
                  <td>3</td>
                  <td>4</td>
                  <td>5</td>
                  <td>6</td>
                  <td>7</td>
                  <td>98</td>
                </tr>
                <tr>
                  <td>6</td>
                  <td>7</td>
                  <td>8</td>
                  <td>9</td>
                  <td>10</td>
                  <td>11</td>
                  <td>12</td>
                  <td>13</td>
                </tr>
                <tr>
                  <td>A</td>
                  <td>B</td>
                  <td>C</td>
                  <td>D</td>
                  <td>E</td>
                  <td>F</td>
                  <td>G</td>
                  <td>H</td>
                </tr>
                <tr>
                  <td>J</td>
                  <td>K</td>
                  <td>L</td>
                  <td>M</td>
                  <td>N</td>
                  <td>O</td>
                  <td>P</td>
                  <td>Q</td>
                </tr>
              </tbody></table>
            <br />
            <div className="plus-quotes">
              <a href="./quotecreation"><i className="fa fa-plus" /></a>
            </div>


          </form>
          {/* delete and update  opportunity */}
          <div className="deleteoppo">
            <button className="update_opportunity" onClick={handleUpdateOpportunity}>UPDATE OPPORTUNITY</button>
            <button className="delete_opportunity" onClick={deleteOpportunity}>DELETE OPPORTUNITY</button>

          </div>

        </div>

      </div>
      {/* sidebar */}



      <div className="slide">
        <div id="mySidebar" className="sidebar1">
          {/* <a href="javascript:void(0)" >x</a> */}
          <div className="navside">
            <button className="dropdown-btn" id="sidetogglebid" onClick={handleClickBidTeam}>BID TEAM<i className="fa fa-angle-down" style={{ color: 'black', fontSize: '12px', float: 'right' }} />
            </button>
            <div className="dropdown-container" id='bidOpen'>
              <form action="">
                <input type="search" name="ownerName" id="oname" placeholder="Enter Name to be searched" required />
                <input type="submit" id="search" hidden />
                <label for="search"><i className="fa-solid fa-check"></i></label>
              </form>
            </div>
          </div>
          <div className="navside">
            <button className="dropdown-btn" id="sidetoggle" onClick={handleClickFiles}>FILES<i className="fa fa-angle-down" style={{ color: 'black', fontSize: '12px', float: 'right' }} />
            </button>
            <div class="dropdown-container" id='filesOpen'>
              <ul id="file-list"></ul>
              <div class="file-upload">
                <input id="file-input" type="file" onChange={displayFileName} multiple hidden />
                <label for="file-input" id="filePlus"><i class="fa fa-plus"></i></label>
              </div>
            </div>
          </div>
          <div className="navside"> <button className="dropdown-btn" id="sidetoggle1" onClick={handleClickNotes}>NOTES <i className="fa fa-angle-down" style={{ color: 'black', fontSize: '12px', float: 'right' }} />
          </button>
            <div className="dropdown-container" id='notesOpen'>
              <form action><input type="text" name="new" id="send" placeholder="Type your Note" /><button id="sent1"><i className="fa fa-send-o blue-color" style={{ color: '#045679' }} /></button> </form>
            </div>
          </div>
          <div className="navside">
            <button className="dropdown-btn" id="sidetoggle2" onClick={handleClickExternal}>EXTERNAL REFERENCES
              <i className="fa fa-angle-down" style={{ color: 'black', fontSize: '12px', float: 'right' }} />
            </button>
            <div className="dropdown-container" id='externOpen'>
              <form action><input type="text" name="id" id="eid" /><label htmlFor id="lab">EXTERNAL
                REFERENCES ID</label><br />
                <input type="text" name="id" id="eid2" />
                <label htmlFor id="lab">EXTERNAL REFERENCES ID 2</label>
                <br /><input type="text" name="id" id="cfid" /><label htmlFor id="lab">CRM
                  REFERENCE</label>
              </form>
            </div>
          </div>
          <div className="navside"> <button className="dropdown-btn" id="sidetoggle3" onClick={handleClickTimestamp}>TIMESTAMP
            <i className="fa fa-angle-down" style={{ color: 'black', fontSize: '12px', float: 'right' }} />
          </button>
            <div className="side-container" id='timesOpen'>
              <span id="time">CREATED BY:</span><span id="time1"></span><br />
              <hr id="lhr" /> <span id="time">CREATED:</span><span id="time1"></span><br />
              <hr id="lhr" /> <span id="time">LAST MODIFIED BY:</span><span id="time1"></span><br />
              <hr id="lhr" /> <span id="time">LAST MODIFIED</span><span id="time1"></span><br />
              <hr id="lhr" /> <span id="time">REVISION</span><span id="time1"></span><br />
            </div>
          </div>

        </div>
        <div>
          <button id="mainsidebaroppo" className="openbtnact" onClick={handleOpenCloseOppo}>
            <i className="fa-light fa-less-than" style={{ fontSize: '14px' }} /></button>
        </div>
      </div>

    </div>
  )
}

export default OpportunitiesData