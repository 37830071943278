import React, {useEffect} from "react";
//Logout api
import { useUserLogout } from "../../hooks/useUser"
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';

//Context
import { useAuthContext } from "../../hooks/useAuthContext";
import HelpRequest from "../../components/HelpRequest";

const Logout = () => {

    const {logout, success, error, isLoading} = useUserLogout();
    const {user} = useAuthContext();

    useEffect(() => {
        if (user){

            logout();
            
           
            window.location.href="/";
        }
  
    }, [user]);

    return (<>
    <HelpRequest/>
    </>)
  
};
export default Logout;