import React, { useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import { myFunction2Write } from './WriteFlexjs';

const WriteFlex = ({ showPlusIcon, dbAccountData, onSelectUser, resetInputFields }) => {
  const [selectedItemIndex, setSelectedItemIndex] = useState(0);
  const [displayLimit, setDisplayLimit] = useState(8);

  const handleToggleView = () => {
    // Toggle between "VIEW MORE" and "VIEW LESS"
    if (displayLimit === 8) {
      setDisplayLimit(dbAccountData ? dbAccountData.length : 8);
    } else {
      setDisplayLimit(8);
    }
  };

  const resetSelection = () => {
    setSelectedItemIndex(-1); // Reset selectedItemIndex to -1
  };

  const renderedItems = dbAccountData
    ? dbAccountData.slice(0, displayLimit).map((account, index) => (
        <li
          id='list_select'
          key={index}
          onClick={() => {
            onSelectUser(account);
            setSelectedItemIndex(index);
          }}
          className={selectedItemIndex === index ? 'selected1' : ''}
        >
          <a>{account.accounts}</a>
        </li>
      ))
    : null;

  return (
    <div>
      <div className="leftwrite" style={{ position: 'fixed' }}>
        <div className="inboxwrite">
          <i className="fa fa-search" aria-hidden="true" />
          <input type="text" id="mySearchwrite" onKeyUp={myFunction2Write} title="type in a chategory" />
        </div>
        <div className="dividewrite_account">
          <ul id="myMenuwrite">
            <span id="dotswrite">{renderedItems}</span>
          </ul>
        </div>
        <div id="morewrite">
          {dbAccountData && dbAccountData.length > 8 ? (
            <button onClick={handleToggleView} className="view-more-button" id='myBTNwrite'>
              {displayLimit === 8 ? 'VIEW MORE' : 'VIEW LESS'}
            </button>
          ) : null}
        </div>
        <div className="plusicon">
          <i className="fa fa-plus" onClick={() => { resetInputFields(); resetSelection(); }} />
        </div>
      </div>
    </div>
  );
};

export default WriteFlex;
