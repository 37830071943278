import React, { useEffect, useState, useRef } from "react";
import "./Opportunities.css";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import { useAuthContext } from "../../hooks/useAuthContext";
import { faCaretUp, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Opportunities = () => {
  const { user } = useAuthContext();
  console.log(user);

  // -----------------------------------
  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [selectedCloseDate, setSelectedCloseDate] = useState("");
  // Define your date change handler
  const handleCloseDateChange = (date) => {
    setSelectedCloseDate(date.toISOString());
  };
  // Define your date change handler
  const handleStartDateChange = (date) => {
    setSelectedStartDate(date.toISOString());
  };

  const [isOpenStage, setIsOpenStage] = useState(false);
  const [optionsStage, setOptionsContent03] = useState([
    "QUALIFY ",
    "SCOPE ",
    "GO NO-GO ",
    "VALIDATE SOLUTION ",
    "BUSINESS CASE ",
    "NEGOTIATE & CLOSE ",
    "DISCOVERY ",
    "CLOSED-NO SALE",
  ]);

  const [isOpenRegion, setIsOpenRegion] = useState(false);
  const [optionsRegion, setOptionsContent05] = useState([
    "AP ",
    "EMEA ",
    "LA ",
    "NA ",
  ]);

  const [isOpenVertical, setIsOpenVertical] = useState(false);
  const [optionsVertical, setOptionsContent052] = useState([
    "BANKING ",
    "CHEMICALS",
    "CONSTRUCTION & ENGG ",
    "CONSULTING SERVICE ",
    "CONSUMER PRODUCTS ",
    "EDUCATION ",
    "ENTERTAINMENT & LEISURE",
    "FINANCIAL SERVICES",
  ]);

  const [isOpenPractice, setIsOpenPractice] = useState(false);
  const [optionsPractice, setOptionsContent053] = useState([
    "DBA",
    "DSOM",
    "EDUCATION",
    "IZOT",
  ]);

  const [isOpenCurrency, setIsOpenCurrency] = useState(false);
  const [optionsCurrency, setOptionsContent06] = useState([
    "Afghan Afghani ",
    "Albanian Lek ",
    "Algerian Dinar ",
    "Angolan Kwanza ",
    "Argentine Peso",
    "Armenian Dram",
    "Aruban Florin",
    "Australian Dollar",
    "Azerbaijani Manat",
    "Bahamian Dollar",
    "Bahraini Dinar",
    "Bangladeshi Taka",
    "Barbadian Dollar",
    "Belarusian Ruble",
    "Belgian Franc",
    "Belize Dollar",
    "Bermudan Dollar",
    "Bhutanese Ngultrum",
    "Bitcoin",
    "Bolivian",
    "Bosnia-Herzegovina",
    "Botswanan Pula",
    "Brazilian Real",
    "British Pound Sterling",
    "Brunei Dollar",
    "Bulgarian Lev",
    "Burundian Franc",
  ]);

  const [isOpenOrg, setIsOpenOrg] = useState(false);
  const [optionsOrg, setOptionsContent061] = useState([
    "AFRICA",
    "ALL OTHER AP",
    "AP-ALL OTHER",
    "AP-AUS/JP",
    "AP-CHINA",
    "AP-INDIA",
    "ARGENTINA",
    "AUSTRALIA",
  ]);

  const [isOpenOpportunityType, setIsOpenOpportunityType] = useState(false);
  const [optionsOpportunityType, setOptionsContent062] = useState([
    "CONSULTING SERVICES OPPORTUNITY",
    "EDUCATIONAL SERVICES OPPORTUNITY",
  ]);
  const [stage, setStage] = useState("");
  const [searchTermStage, setSearchTermStage] = useState("");

  const [region, setRegion] = useState("");
  const [searchTermRegion, setSearchTermRegion] = useState("");

  const [vertical, setVertical] = useState("");
  const [searchTermVertical, setSearchTermVertical] = useState("");

  const [practice, setPractice] = useState("");
  const [searchTermPractice, setSearchTermPractice] = useState("");

  const [currency, setCurrency] = useState("");
  const [searchTermCurrency, setSearchTermCurrency] = useState("");

  const [org, setOrg] = useState("");
  const [searchTermOrg, setSearchTermOrg] = useState("");

  const [opportunityType, setOpportunityType] = useState("");
  const [searchTermOpportunityType, setSearchTermOpportunityType] =
    useState("");

  const toggleDropdownStage = () => {
    setIsOpenStage(!isOpenStage);
    if (!isOpenStage) {
      setSearchTermStage("");
    }
  };

  const toggleDropdownRegion = () => {
    setIsOpenRegion(!isOpenRegion);
    if (!isOpenRegion) {
      setSearchTermRegion("");
    }
  };

  const toggleDropdownVertical = () => {
    setIsOpenVertical(!isOpenVertical);
    if (!isOpenVertical) {
      setSearchTermVertical("");
    }
  };

  const toggleDropdownPractice = () => {
    setIsOpenPractice(!isOpenPractice);
    if (!isOpenPractice) {
      setSearchTermPractice("");
    }
  };

  const toggleDropdownCurrency = () => {
    setIsOpenCurrency(!isOpenCurrency);
    if (!isOpenCurrency) {
      setSearchTermCurrency("");
    }
  };

  const toggleDropdownOrg = () => {
    setIsOpenOrg(!isOpenOrg);
    if (!isOpenOrg) {
      setSearchTermOrg("");
    }
  };

  const toggleDropdownOpportunityType = () => {
    setIsOpenOpportunityType(!isOpenOpportunityType);
    if (!isOpenOpportunityType) {
      setSearchTermOpportunityType("");
    }
  };

  const handleInputChangeStage = (e) => {
    const inputValueStage = e.target.value;
    setSearchTermStage(inputValueStage);

    if (!isOpenStage) {
      setIsOpenStage(true);
    }
  };

  const handleInputChangeRegion = (e) => {
    const inputValueRegion = e.target.value;
    setSearchTermRegion(inputValueRegion);

    if (!isOpenRegion) {
      setIsOpenRegion(true);
    }
  };

  const handleInputChangeVertical = (e) => {
    const inputValueVertical = e.target.value;
    setSearchTermVertical(inputValueVertical);

    if (!isOpenVertical) {
      setIsOpenVertical(true);
    }
  };

  const handleInputChangePractice = (e) => {
    const inputValueContent053 = e.target.value;
    setSearchTermPractice(inputValueContent053);

    if (!isOpenPractice) {
      setIsOpenPractice(true);
    }
  };

  const handleInputChangeCurrency = (e) => {
    const inputValueCurrency = e.target.value;
    setSearchTermCurrency(inputValueCurrency);

    if (!isOpenCurrency) {
      setIsOpenCurrency(true);
    }
  };

  const handleInputChangeOrg = (e) => {
    const inputValueOrg = e.target.value;
    setSearchTermOrg(inputValueOrg);

    if (!isOpenOrg) {
      setIsOpenOrg(true);
    }
  };

  const handleInputChangeOpportunityType = (e) => {
    const inputValueOpportunityType = e.target.value;
    setSearchTermOpportunityType(inputValueOpportunityType);

    if (!isOpenOpportunityType) {
      setIsOpenOpportunityType(true);
    }
  };

  const selectOptionStage = (option) => {
    setStage(option);
    setSearchTermStage(option);
    setIsOpenStage(false);
  };

  const selectOptionRegion = (option) => {
    setRegion(option);
    setSearchTermRegion(option);
    setIsOpenRegion(false);
  };
  const selectOptionVertical = (option) => {
    setVertical(option);
    setSearchTermVertical(option);
    setIsOpenVertical(false);
  };

  const selectOptionPractice = (option) => {
    setPractice(option);
    setSearchTermPractice(option);
    setIsOpenPractice(false);
  };

  const selectOptionCuirrency = (option) => {
    setCurrency(option);
    setSearchTermCurrency(option);
    setIsOpenCurrency(false);
  };

  const selectOptionOrg = (option) => {
    setOrg(option);
    setSearchTermOrg(option);
    setIsOpenOrg(false);
  };

  const selectOptionOpportunityType = (option) => {
    setOpportunityType(option);
    setSearchTermOpportunityType(option);
    setIsOpenOpportunityType(false);
  };
  const filteredOptionsStage = optionsStage.filter((option) =>
    option.toLowerCase().includes(searchTermStage.toLowerCase())
  );

  const filteredOptionsRegion = optionsRegion.filter((option) =>
    option.toLowerCase().includes(searchTermRegion.toLowerCase())
  );
  const filteredOptionsVertical = optionsVertical.filter((option) =>
    option.toLowerCase().includes(searchTermVertical.toLowerCase())
  );

  const filteredOptionsPractice = optionsPractice.filter((option) =>
    option.toLowerCase().includes(searchTermPractice.toLowerCase())
  );

  const filteredOptionsCurrency = optionsCurrency.filter((option) =>
    option.toLowerCase().includes(searchTermCurrency.toLowerCase())
  );

  const filteredOptionsOrg = optionsOrg.filter((option) =>
    option.toLowerCase().includes(searchTermOrg.toLowerCase())
  );

  const filteredOptionsOpportunityType = optionsOpportunityType.filter(
    (option) =>
      option.toLowerCase().includes(searchTermOpportunityType.toLowerCase())
  );
  const dropdownRefStage = useRef(null);
  const dropdownRefRegion = useRef(null);
  const dropdownRefVertical = useRef(null);
  const dropdownRefPractice = useRef(null);
  const dropdownRefCurrency = useRef(null);
  const dropdownRefOrg = useRef(null);
  const dropdownRefOpportunityType = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        dropdownRefStage.current &&
        !dropdownRefStage.current.contains(event.target)
      ) {
        setIsOpenStage(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        dropdownRefRegion.current &&
        !dropdownRefRegion.current.contains(event.target)
      ) {
        setIsOpenRegion(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        dropdownRefVertical.current &&
        !dropdownRefVertical.current.contains(event.target)
      ) {
        setIsOpenVertical(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        dropdownRefPractice.current &&
        !dropdownRefPractice.current.contains(event.target)
      ) {
        setIsOpenPractice(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        dropdownRefCurrency.current &&
        !dropdownRefCurrency.current.contains(event.target)
      ) {
        setIsOpenCurrency(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        dropdownRefOrg.current &&
        !dropdownRefOrg.current.contains(event.target)
      ) {
        setIsOpenOrg(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        dropdownRefOpportunityType.current &&
        !dropdownRefOpportunityType.current.contains(event.target)
      ) {
        setIsOpenOpportunityType(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // ---------------------------------------
  const [accountkay, setAccountKay] = useState(null);
  const [opportunityName, setOpportunityName] = useState("");
  const [netPrice, setNetPrice] = useState("");
  const [margin, setMargin] = useState("");
  const [cost, setCost] = useState(0);
  //  const [stage, setStage] = useState('');
  const [probability, setProbability] = useState("");
  const [hours, setHours] = useState("");
  const [durationWeek, setDurationWeek] = useState(0);
  const [owner, setOwner] = useState("");
  //  const [region, setRegion] = useState('');
  //  const [vertical, setVertical] = useState('');
  //  const [practice, setPractice] = useState('');
  //  const [currency, setCurrency] = useState('');
  //  const [org, setOrg] = useState('');
  //  const [opportunityType, setOpportunityType] = useState('');

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const dataParam = urlParams.get("data");

    if (dataParam) {
      const decodedData = JSON.parse(decodeURIComponent(dataParam));
      setAccountKay(decodedData);
    }
  }, []);

  console.log(accountkay);

  const saveOpportunity = async (event) => {
    if (
      !opportunityName ||
      !stage ||
      !selectedCloseDate ||
      !selectedCloseDate
    ) {
      toast.info(
        "Please fill in the 'OPPORTUNITYNAME,STAGE,START AND CLOSE DATE' fields."
      );
      return;
    }
    event.preventDefault();
    try {
      const response = await fetch("/api/opportunity/add", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify({
          account_key: accountkay,
          opportunity_name: opportunityName,
          net_price: netPrice,
          margin: margin,
          cost: cost,
          stage: stage,
          probability: probability,
          hours: hours,
          close: selectedCloseDate,
          start: selectedStartDate,
          duration_weeks: durationWeek,
          owner: owner,
          region: region,
          vertical: vertical,
          practice: practice,
          currency: currency,
          org: org,
          opportunity_type: opportunityType,
        }),
      });
      if (response.ok) {
        console.log("success");
        alert("Opportunity Created Successfully.");
        window.location.href = "/account";
      } else {
        console.error("Request failed:", response.status);
        alert("Opportunity already exits");
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const [opportunityName, setOpportunityName] = useState('');

  const [displayErrorMessage, setDisplayErrorMessage] = useState(false);
  const [displayOpportunityName, setDisplayOpportunityName] = useState(true);
  const contentO1input = document.querySelector(".contentO1input");
  const handleBlur = () => {
    const trimmedValue = opportunityName.trim();
    if (trimmedValue === "") {
      setDisplayErrorMessage(true);
      setDisplayOpportunityName(false);
      contentO1input.style.outlineColor = "red";
      contentO1input.style.border = " 2px solid red";
      contentO1input.style.borderLeft = "0px";
    } else {
      setDisplayErrorMessage(false);
      setDisplayOpportunityName(true);
      contentO1input.style.outlineColor = "#045679";
      contentO1input.style.border = " 1px solid #ccc";
    }
  };
  return (
    <div>
      <Navbar />
      <Sidebar />
      <div className="bread">
        <ul className="breadcrumbs">
          <li className="breadcrumbs--item">
            <a
              href="./home"
              className="breadcrumbs--link breadcrumbs"
              style={{ display: "inline", textDecoration: "none" }}
            >
              HOME
            </a>
          </li>
          <li className="breadcrumbs--item">
            <a
              href="./account"
              className="breadcrumbs--link breadcrumbs"
              style={{ display: "inline", textDecoration: "none" }}
            >
              ACCOUNTS
            </a>
          </li>
          <li className="breadcrumbs--item">
            <a className="breadcrumbs--link breadcrumbs--link--active">
              OPPORTUNITY
            </a>
          </li>
        </ul>
        <hr className="hr" />
      </div>
      <div className="oppo">
        <div id="opportunitiesheader">
          <a>NEW OPPORTUNITY</a>
        </div>
        <form action>
          <div className="containerO1">
            <div
              id="contentO1"
              style={{
                borderLeft:
                  opportunityName.length === 0 ? "3px solid #216c98" : "none",
              }}
            >
              <input
                className="contentO1input"
                type="text"
                onChange={(e) => setOpportunityName(e.target.value)}
                onBlur={handleBlur}
                value={opportunityName}
                required
              />

              {displayOpportunityName && (
                <label className="opportunityname">OPPORTUNITY</label>
              )}

              {displayErrorMessage && (
                <label className="errormsg">Required</label>
              )}
            </div>
          </div>
          <div className="containerO2">
            <div id="contentO2">
              <input
                className
                type="number"
                min={0}
                placeholder="$0.00"
                onChange={(e) => setNetPrice(e.target.value)}
                value={netPrice}
              />
              <label>NET PRICE</label>
            </div>
            <div id="contentO2">
              <input
                className
                type="number"
                min="0%"
                placeholder="0%"
                onChange={(e) => setMargin(e.target.value)}
                value={margin}
              />
              <label>MARGIN</label>
            </div>
            <div id="contentO2">
              <input
                className
                type="number"
                min={0}
                placeholder="$0.00"
                onChange={(e) => setCost(e.target.value)}
                value={cost}
              />
              <label>COST</label>
            </div>
          </div>
          <div className="containerO3">
            <div className="containt03container" ref={dropdownRefStage}>
              <div
                id="contentO3"
                style={{
                  borderLeft: stage.length === 0 ? "3px solid #216c98" : "none",
                }}
              >
                <input
                  type="text"
                  className="container03input"
                  value={isOpenStage ? searchTermStage : stage}
                  onChange={handleInputChangeStage}
                  onClick={toggleDropdownStage}
                  readOnly={!isOpenStage} // Set readOnly attribute based on isOpen
                />
                <span
                  className="dropdown-icon-content03"
                  onClick={toggleDropdownStage}
                >
                  {isOpenStage ? (
                    <FontAwesomeIcon icon={faCaretUp} />
                  ) : (
                    <FontAwesomeIcon icon={faCaretDown} />
                  )}
                </span>

                <label>STAGE</label>
              </div>
              {isOpenStage && (
                <div
                  className="dropdown-list-content03"
                  id="dropdownlistcontent03"
                >
                  {filteredOptionsStage.length === 0 ? (
                    <div className="no-results">No results found</div>
                  ) : (
                    filteredOptionsStage.map((option, index) => (
                      <div
                        key={index}
                        className="dropdown-list-item-content03"
                        onClick={() => selectOptionStage(option)}
                      >
                        {option}
                      </div>
                    ))
                  )}
                </div>
              )}
            </div>
            <div id="contentO3">
              <input
                type="number"
                min={0}
                placeholder="0%"
                onChange={(e) => setProbability(e.target.value)}
                value={probability}
              />
              <label>PROBABILITY</label>
            </div>
            <div id="contentO3">
              <input
                className
                type="number"
                min={0}
                onChange={(e) => setHours(e.target.value)}
                value={hours}
              />
              <label>HOURS</label>
            </div>
          </div>
          <div className="containerO4">
            <div
              id="contentO4"
              style={{
                borderLeft:
                  selectedCloseDate.length === 0 ? "3px solid #216c98" : "none",
              }}
            >
              <Datetime
                value={selectedCloseDate}
                onChange={handleCloseDateChange}
                dateFormat="MMMM D, YYYY" // Customize the date format
                timeFormat={false} // Set to true to include time selection
                inputProps={{ placeholder: "Select a date" }}
              />
              <label>CLOSE</label>
            </div>

            <div
              id="contentO4"
              style={{
                borderLeft:
                  selectedStartDate.length === 0 ? "3px solid #216c98" : "none",
              }}
            >
              <Datetime
                value={selectedStartDate}
                onChange={handleStartDateChange}
                dateFormat="MMMM D, YYYY" // Customize the date format
                timeFormat={false} // Set to true to include time selection
                inputProps={{ placeholder: "Select a date" }}
              />
              <label>START</label>
            </div>
            <div id="contentO4">
              <input
                className
                type="number"
                // min={1}
                onChange={(e) => setDurationWeek(e.target.value)}
                value={durationWeek}
              />
              <label>DURATION WEEKS</label>
            </div>
          </div>

          <b>GENERAL</b>

          <div className="containerO5">
            <div id="contentO5">
              <input type="text"></input>
              <label>OWNER</label>
            </div>
            <div className="content5Container1" ref={dropdownRefRegion}>
              <div id="contentO5">
                {/* <img className="avatar__image" src="../src/pages/images/avtar.jpg" /> */}
                <input
                  type="text"
                  className="container05input"
                  value={isOpenRegion ? searchTermRegion : region}
                  onChange={handleInputChangeRegion}
                  onClick={toggleDropdownRegion}
                  readOnly={!isOpenRegion} // Set readOnly attribute based on isOpen
                />
                <span
                  className="dropdown-icon-content05"
                  onClick={toggleDropdownRegion}
                >
                  {isOpenRegion ? (
                    <FontAwesomeIcon icon={faCaretUp} />
                  ) : (
                    <FontAwesomeIcon icon={faCaretDown} />
                  )}
                </span>

                <label>REGION</label>
              </div>
              {isOpenRegion && (
                <div
                  className="dropdown-list-content05"
                  id="dropdownlistcontent05"
                >
                  {filteredOptionsRegion.length === 0 ? (
                    <div className="no-results">No results found</div>
                  ) : (
                    filteredOptionsRegion.map((option, index) => (
                      <div
                        key={index}
                        className="dropdown-list-item-content05"
                        onClick={() => selectOptionRegion(option)}
                      >
                        {option}
                      </div>
                    ))
                  )}
                </div>
              )}
            </div>
            <div id="content5Container2" ref={dropdownRefVertical}>
              <div id="contentO52">
                <input
                  type="text"
                  className="container052input2"
                  value={isOpenVertical ? searchTermVertical : vertical}
                  onChange={handleInputChangeVertical}
                  onClick={toggleDropdownVertical}
                  readOnly={!isOpenVertical} // Set readOnly attribute based on isOpen
                />
                <span
                  className="dropdown-icon-content052"
                  onClick={toggleDropdownVertical}
                >
                  {isOpenVertical ? (
                    <FontAwesomeIcon icon={faCaretUp} />
                  ) : (
                    <FontAwesomeIcon icon={faCaretDown} />
                  )}
                </span>
                <label>VERTICAL</label>
              </div>
              {isOpenVertical && (
                <div
                  className="dropdown-list-content052"
                  id="dropdownlistcontent052"
                >
                  {filteredOptionsVertical.length === 0 ? (
                    <div className="no-results">No results found</div>
                  ) : (
                    filteredOptionsVertical.map((option, index) => (
                      <div
                        key={index}
                        className="dropdown-list-item-content052"
                        onClick={() => selectOptionVertical(option)}
                      >
                        {option}
                      </div>
                    ))
                  )}
                </div>
              )}
            </div>
            <div className="content5Container3" ref={dropdownRefPractice}>
              <div id="contentO53">
                <input
                  type="text"
                  className="container05input3"
                  value={isOpenPractice ? searchTermPractice : practice}
                  onChange={handleInputChangePractice}
                  onClick={toggleDropdownPractice}
                  readOnly={!isOpenPractice} // Set readOnly attribute based on isOpen
                />
                <span
                  className="dropdown-icon-content053"
                  onClick={toggleDropdownPractice}
                >
                  {isOpenPractice ? (
                    <FontAwesomeIcon icon={faCaretUp} />
                  ) : (
                    <FontAwesomeIcon icon={faCaretDown} />
                  )}
                </span>

                <label>PRACTICE</label>
              </div>
              {isOpenPractice && (
                <div
                  className="dropdown-list-content053"
                  id="dropdownlistcontent053"
                >
                  {filteredOptionsPractice.length === 0 ? (
                    <div className="no-results">No results found</div>
                  ) : (
                    filteredOptionsPractice.map((option, index) => (
                      <div
                        key={index}
                        className="dropdown-list-item-content053"
                        onClick={() => selectOptionPractice(option)}
                      >
                        {option}
                      </div>
                    ))
                  )}
                </div>
              )}
            </div>
          </div>

          <div className="containerO6">
            <div className="content6container1" ref={dropdownRefCurrency}>
              <div id="contentO6">
                <input
                  type="text"
                  className="container06input"
                  value={isOpenCurrency ? searchTermCurrency : currency}
                  onChange={handleInputChangeCurrency}
                  onClick={toggleDropdownCurrency}
                  readOnly={!isOpenCurrency} // Set readOnly attribute based on isOpen
                />
                <span
                  className="dropdown-icon-content06"
                  onClick={toggleDropdownCurrency}
                >
                  {isOpenCurrency ? (
                    <FontAwesomeIcon icon={faCaretUp} />
                  ) : (
                    <FontAwesomeIcon icon={faCaretDown} />
                  )}
                </span>
                <label>CURRENCY</label>
              </div>
              {isOpenCurrency && (
                <div
                  className="dropdown-list-content06"
                  id="dropdownlistcontent06"
                >
                  {filteredOptionsCurrency.length === 0 ? (
                    <div className="no-results">No results found</div>
                  ) : (
                    filteredOptionsCurrency.map((option, index) => (
                      <div
                        key={index}
                        className="dropdown-list-item-content06"
                        onClick={() => selectOptionCuirrency(option)}
                      >
                        {option}
                      </div>
                    ))
                  )}
                </div>
              )}
            </div>
            <div id="contentO6" ref={dropdownRefOrg}>
              <div id="contentO61">
                <input
                  type="text"
                  className="container06input1"
                  value={isOpenOrg ? searchTermOrg : org}
                  onChange={handleInputChangeOrg}
                  onClick={toggleDropdownOrg}
                  readOnly={!isOpenOrg} // Set readOnly attribute based on isOpen
                />
                <span
                  className="dropdown-icon-content061"
                  onClick={toggleDropdownOrg}
                >
                  {isOpenOrg ? (
                    <FontAwesomeIcon icon={faCaretUp} />
                  ) : (
                    <FontAwesomeIcon icon={faCaretDown} />
                  )}
                </span>
                <label>ORG</label>
              </div>
              {isOpenOrg && (
                <div
                  className="dropdown-list-content061"
                  id="dropdownlistcontent061"
                >
                  {filteredOptionsOrg.length === 0 ? (
                    <div className="no-results">No results found</div>
                  ) : (
                    filteredOptionsOrg.map((option, index) => (
                      <div
                        key={index}
                        className="dropdown-list-item-content061"
                        onClick={() => selectOptionOrg(option)}
                      >
                        {option}
                      </div>
                    ))
                  )}
                </div>
              )}
            </div>
            <div id="contentO6" ref={dropdownRefOpportunityType}>
              <div id="contentO62">
                <input
                  type="text"
                  className="container06input2"
                  value={
                    isOpenOpportunityType
                      ? searchTermOpportunityType
                      : opportunityType
                  }
                  onChange={handleInputChangeOpportunityType}
                  onClick={toggleDropdownOpportunityType}
                  readOnly={!isOpenOpportunityType} // Set readOnly attribute based on isOpen
                />
                <span
                  className="dropdown-icon-content062"
                  onClick={toggleDropdownOpportunityType}
                >
                  {isOpenOpportunityType ? (
                    <FontAwesomeIcon icon={faCaretUp} />
                  ) : (
                    <FontAwesomeIcon icon={faCaretDown} />
                  )}
                </span>
                <label>OPPRTUNITY TYPE</label>
              </div>
              {isOpenOpportunityType && (
                <div
                  className="dropdown-list-content062"
                  id="dropdownlistcontent062"
                >
                  {filteredOptionsOpportunityType.length === 0 ? (
                    <div className="no-results">No results found</div>
                  ) : (
                    filteredOptionsOpportunityType.map((option, index) => (
                      <div
                        key={index}
                        className="dropdown-list-item-content062"
                        onClick={() => selectOptionOpportunityType(option)}
                      >
                        {option}
                      </div>
                    ))
                  )}
                </div>
              )}
            </div>
          </div>

          <div className="saveandcancel">
            <button id="reset1" type="reset" style={{ color: "red" }}>
              CANCEL
            </button>
            <button
              id="save1"
              type="submit"
              onClick={saveOpportunity}
              style={{ color: "green" }}
            >
              SAVE
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Opportunities;
