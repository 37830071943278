import React from 'react'
import SpreadJSApp from '../../components/SpreadJsApp';

function Calc() {
  return (
    <>
    <SpreadJSApp/>
    </>
  )
}

export default Calc;